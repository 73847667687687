import { useMemo } from "preact/hooks";
import { MenuItemAge, MenuItemGender } from "@_types/MenuItems";
import { Age, Gender } from "@_types/Enums";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";

type SingleAndPlural = [string, string];

export default function useChildLabelFunction(gender: MenuItemGender, age: MenuItemAge): ChildLabelFunction {
  const suffixes: SingleAndPlural = useMemo(() => {
    return choiceMaleOrFemale(
      age.value,
      gender.value,
      ["acompanhado", "acompanhados"],
      ["acompanhada", "acompanhadas"]
    );
  }, [gender, age]);

  const prepositions: SingleAndPlural = useMemo(() => {
    return choiceMaleOrFemale(age.value, gender.value, ["do", "dos"], ["da", "das"]);
  }, [gender, age]);

  const labels: SingleAndPlural = useMemo(() => {
    if (age.value === Age["0a19a"]) {
      return ["pessoa", "pessoas"];
    }

    if (age.value === Age["10a19a"] && gender.value === Gender.all) {
      return ["adolescente", "adolescentes"];
    }

    if (age.value === Age["10a19a"] && gender.value === Gender.m) {
      return ["menino adolescente", "meninos adolescentes"];
    }

    if (age.value === Age["10a19a"] && gender.value === Gender.f) {
      return ["menina adolescente", "meninas adolescentes"];
    }

    if (gender.value === Gender.all) {
      return ["criança", "crianças"];
    }

    if (gender.value === Gender.m) {
      return ["menino", "meninos"];
    }

    if (gender.value === Gender.f) {
      return ["menina", "meninas"];
    }

    throw Error("Dados não identificados");
  }, [gender, age]);

  return (value: number, usePrepositions = false, useSuffix = false) => {
    let label = value === 1 ? labels[0] : labels[1];

    if (usePrepositions) {
      const preposition = value === 1 ? prepositions[0] : prepositions[1];
      label = `${preposition} ${label}`;
    }

    if (useSuffix) {
      const suffix = value === 1 ? suffixes[0] : suffixes[1];
      label = `${label} ${suffix}`;
    }

    return label;
  };
}

function choiceMaleOrFemale(age: Age, gender: Gender, male: SingleAndPlural, female: SingleAndPlural): SingleAndPlural {
  if (age === Age["0a19a"]) {
    return female;
  }

  if (gender === Gender.all && [Age["0m5a"], Age["5a10a"], Age["0a10a"]].includes(age)) {
    return female;
  }

  if (gender === Gender.f) {
    return female;
  }

  return male;
}
