import { Fragment, h, JSX } from "preact";
import { SvgIconAvailable } from "@_types/Svg";
import SvgIcon from "./SvgIcon";

type Props = {
  icons: SvgIconAvailable[];
};

export default function SvgIcons(props: Props): JSX.Element {
  return (
    <Fragment>
      {props.icons.map((icon: SvgIconAvailable) => {
        return <SvgIcon key={icon} icon={icon} />;
      })}
    </Fragment>
  );
}
