import { h, JSX } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Local } from "@_types/Local";
import { MenuItemAge } from "@_types/MenuItems";
import HeaderTitle from "@components/HeaderTitle";
import NavFilter from "@components/NavFilter";
import textFood from "@texts/textFood";
import foodAgeMenuItems from "@data/foodAgeMenuItems";
import { NutritionFood } from "@_types/NutritionFood";
import SectionFoodContent from "@components/SectionFoodContent";
import genderMenuItems from "@data/genderMenuItems";
import useChildLabelFunction from "@hooks/useChildLabelFunction";
import useHistoryDescription from "@hooks/useHistoryDescription";
import skinMenuItems from "@data/skinMenuItems";
import { Age } from "@_types/Enums";

export type IndicatorsType = "good" | "bad";

let selectedAge: MenuItemAge = foodAgeMenuItems[1];
let selectedIndicatorsType: IndicatorsType = "bad";

type Props = {
  local: Local;
  nutritionFood: NutritionFood[] | undefined;
  year: number;
};

export default function SectionFood(props: Props): JSX.Element {
  const { year, local, nutritionFood } = props;

  const [age, setAge] = useState<MenuItemAge>(selectedAge);
  const [indicatorsType, setIndicatorsType] = useState<IndicatorsType>(selectedIndicatorsType);

  useEffect(() => {
    selectedAge = age;
  }, [age]);

  useEffect(() => {
    selectedIndicatorsType = indicatorsType;
  }, [indicatorsType]);

  const gender = genderMenuItems[0];
  const skin = skinMenuItems[0];
  const nutritionFoodSelected = nutritionFood?.find((item: NutritionFood) => item.a === age.value && item.y === year);
  const nutritionFoodHistory = nutritionFood?.filter((item: NutritionFood) => item.a === age.value) || [];
  const childLabelFunction = useChildLabelFunction(gender, age);
  const historyDescription = useHistoryDescription(childLabelFunction, age, gender, skin);

  return (
    <section>
      <HeaderTitle title="Consumo Alimentar" local={props.local} description={textFood} year={year} />

      <div class="container">
        <nav className="nav-filters nav-filters-food | flex | margin-vertical">
          <NavFilter title="Faixa Etária" items={foodAgeMenuItems} selected={age} updater={setAge} />

          {age.value !== Age["0m6m"] && (
            <nav className={`flex-grow`}>
              <h3>Conjunto de Indicadores</h3>
              <div className={`button-group`}>
                <label className={`label-as-button ${indicatorsType === "bad" ? "active" : ""}`}>
                  <input
                    type={"radio"}
                    name={"food-indicators"}
                    checked={indicatorsType === "bad"}
                    onClick={() => setIndicatorsType("bad")}
                  />
                  <span>Indicadores de maior risco à saúde</span>
                </label>

                <label className={`label-as-button  ${indicatorsType === "good" ? "active" : ""}`}>
                  <input
                    type={"radio"}
                    name={"food-indicators"}
                    checked={indicatorsType === "good"}
                    onClick={() => setIndicatorsType("good")}
                  />
                  <span>Indicadores de maior benefício à saúde</span>
                </label>
              </div>
            </nav>
          )}
        </nav>
      </div>

      <SectionFoodContent
        year={year}
        age={age}
        local={local}
        nutritionFood={nutritionFoodSelected}
        nutritionFoodHistory={nutritionFoodHistory}
        historyDescription={historyDescription}
        indicatorsType={indicatorsType}
      />
    </section>
  );
}
