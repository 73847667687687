import { h, JSX } from "preact";
import {Skin} from "@_types/Enums";
import formatNumber from "@lib/formatNumber";

type CellPopulationProps = {
  year: number;
  skin: Skin;
  totalChildren: number;
  estimatedPop: number | undefined;
  childLabelFunction: (value: number, preposition?: boolean, suffix?: boolean) => string;
};

export default function SectionNutritionTableCellPopulation(props: CellPopulationProps): JSX.Element {
  const { skin, totalChildren, estimatedPop, childLabelFunction } = props;
  const percent = estimatedPop ? (totalChildren / estimatedPop) * 100 : null;
  let content;

  let asterisk;
  if (skin === Skin.yellow) {
    asterisk = '²';
  } else if (props.year <= 2021) {
    asterisk = '³';
  }

  if (percent) {
    const percentFormatted = formatNumber(percent, 1);
    content = (
      <small>
        {percentFormatted}% {childLabelFunction(totalChildren, true)}
        {asterisk}
      </small>
    );
  } else {
    content = (
      <small>
        {childLabelFunction(totalChildren, false, true)}
        {asterisk}
      </small>
    );
  }

  return (
    <td className="td-pop">
      <strong>{formatNumber(totalChildren, 0)}</strong>
      <br />
      {content}
    </td>
  );
}