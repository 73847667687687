import { useCallback, useEffect } from "preact/hooks";
import { PopoverState, PopoverStateUpdater } from "./Types";
import { RefObject } from "preact";

export default function useDocumentListenerToClosePopoverAfterClick(
  popoverState: PopoverState,
  setPopoverState: PopoverStateUpdater,
  refPopover: RefObject<HTMLDivElement>
): void {
  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (popoverState === null || setPopoverState === null) {
        return;
      }

      if (!(event.target instanceof HTMLElement) || !(refPopover.current instanceof HTMLDivElement)) {
        return;
      }

      const clickInSameElement = popoverState.element.contains(event.target) || popoverState.element === event.target;
      const clickInPopoverElement = refPopover.current.contains(event.target) || refPopover.current === event.target;
      if (clickInSameElement || clickInPopoverElement) {
        return;
      }

      setPopoverState(null);
    },
    [popoverState, setPopoverState, refPopover]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [handleDocumentClick]);
}
