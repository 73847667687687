import { Fragment, h, JSX } from "preact";

export default function AppHeader(): JSX.Element {
  return (
    <Fragment>
      <h1>Panorama da Obesidade em Crianças e Adolescentes</h1>
      <p>
        Dados oficiais do Sistema de Vigilância Alimentar e Nutricional -{" "}
        <a href="https://sisaps.saude.gov.br/sisvan/" target="_blank" rel="noreferrer">
          SISVAN
        </a>{" "}
        no período de <strong>2015 até 2024</strong>.
      </p>
    </Fragment>
  );
}
