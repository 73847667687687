import { Local } from "@_types/Local";

export default function getLocalNameFoodItems(local: Local): string {
  if (local.abbr === "BR") {
    return `${local.preposition} ${local.name}`;
  }

  if (local.abbr) {
    return `${local?.preposition ?? "em"} ${local.name} (${local.abbr.toUpperCase()})`;
  }

  return `${local?.preposition ?? "em"} ${local.name}`;
}
