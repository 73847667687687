import formatNumber from "@lib/formatNumber";
import { NutritionClass, NutritionClassFormatted } from "@_types/NutritionClass";

export default function formatNutritionClass(item: NutritionClass | undefined): NutritionClassFormatted {
  if (item === undefined) {
    return {
      children: 0,
      percent: 0,
      percentPerfectRounded: 0,
      percentFormatted: "0%",
      childrenFormatted: "0"
    };
  }

  return {
    children: item.c,
    percent: item.p,
    percentPerfectRounded: item.ppr,
    percentFormatted: `${formatNumber(item.p, 2)}%`,
    childrenFormatted: `${formatNumber(item.c, 0)}`
  };
}
