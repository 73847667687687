import { useCallback, useContext } from "preact/hooks";
import TooltipContext from "./TooltipContext";
import { MouseEventCallback } from "./Types";

export default function useTooltip(text: string): [MouseEventCallback, MouseEventCallback] {
  const setTooltip = useContext(TooltipContext);
  const onMouseHover = useCallback(
    (event: MouseEvent) => {
      if (setTooltip === null || !(event.target instanceof HTMLElement)) {
        return;
      }

      setTooltip({
        element: event.target,
        text
      });
    },
    [setTooltip, text]
  );

  const onMouseOut = useCallback(() => setTooltip && setTooltip(null), [setTooltip]);

  return [onMouseHover, onMouseOut];
}
