import { h, JSX } from "preact";
import { FoodGroup } from "@_types/Food";
import { useTooltip } from "@components/Overlay";
import { NutritionFoodDictionaryItem, NutritionFoodItem } from "@_types/NutritionFood";
import formatNumber from "@lib/formatNumber";
import SvgHelpIcon from "@components/SvgHelpIcon";
import { ButtonHistorySmall } from "@components/ButtonHistory";
import { NutritionFoodID } from "@_types/Enums";

type Props = {
  group: FoodGroup;
  totalChildren: number;
  childLabelFunction: (value: number) => string;
  localName: string;
  dictionary: NutritionFoodDictionaryItem;
  itemId: NutritionFoodID;
  itemFood: NutritionFoodItem | null;
  year: number;
  onClickHistoryButton: (id: string) => void;
  isHistoryOpen: boolean;
  extraClass: string;
};

export default function SectionFoodItem(props: Props): JSX.Element {
  const { year, dictionary, itemFood, itemId, onClickHistoryButton, isHistoryOpen, extraClass } = props;
  const { title, description } = dictionary;

  const percentFormatted = itemFood ? `${Math.round(itemFood.p)}%` : "--";
  const numberOfChildrenText = itemFood ? buildNumberOfChildrenText(props) : `Nenhum acompanhamento em ${year} `;

  const [onHover, onOut] = useTooltip(numberOfChildrenText);
  const descriptionPopover = `*${numberOfChildrenText}*
    ${description}`;

  return (
    <div className={`${isHistoryOpen ? "mode-history-open" : ""} ${extraClass}`} >
      <div className={"header"}>
        <h2>
          {title}
          <SvgHelpIcon title={title} description={descriptionPopover} />
        </h2>
        <ButtonHistorySmall id={`${itemId}`} isOpen={isHistoryOpen} onClick={onClickHistoryButton} />
      </div>
      <ul>
        <li>
          <strong onMouseEnter={onHover} onMouseOut={onOut}>
            {percentFormatted}
          </strong>
        </li>
      </ul>
    </div>
  );
}

function buildNumberOfChildrenText(props: Props) {
  const { year, itemFood, totalChildren, childLabelFunction, localName } = props;
  const childLabel = childLabelFunction(totalChildren);
  const yesFormatted = `${formatNumber(itemFood?.yes || 0, 0)}`;
  const totalFormatted = `${formatNumber(totalChildren, 0)}`;
  return `${yesFormatted} de um total de ${totalFormatted} ${childLabel} acompanhados ${localName} em ${year}.`;
}
