import SvgIcons from "../SvgIcons";
import { h, JSX } from "preact";
import { MenuItem } from "@_types/MenuItems";

const defaultLabel = "Selecione um ítem";

type Props<T, K> = {
  label: string | undefined;
  itemSelected: MenuItem<T, K> | null | undefined;
  toggleOpen: () => void;
};

export default function Button<T, K>(props: Props<T, K>): JSX.Element {
  const label = props.itemSelected?.text ?? props.label ?? defaultLabel;
  const cssClasses = [props.itemSelected ? "active" : "", props.itemSelected?.extraClass ?? ""];

  const _onClick = (e: Event) => {
    e.preventDefault();
    props.toggleOpen();
  };

  return (
    <button className={cssClasses.join(" ")} onClick={_onClick}>
      <SvgIcons icons={props.itemSelected?.svgIcons ?? []} />
      {props.itemSelected?.img ? (
        <img
          src={`./${props.itemSelected.img}`}
          alt={"Bandeira"}
          width={32}
          height={props.itemSelected.imgHeight ?? 22}
        />
      ) : (
        ""
      )}
      <span>{label}</span>
    </button>
  );
}
