import { Age } from "@_types/Enums";
import {ChildLabelFunction} from "@_types/ChildLabelFunction";

export default function getChildLabelFunctionForFoodItems(age: Age): ChildLabelFunction {
  const labels = getWords(age);
  const suffix = getSuffixes(age);
  return (value: number, usePrepositions?: boolean, useSuffix?: boolean): string => {
    let content = value === 1 ? labels[0] : labels[1];
    if(useSuffix) {
      content += ' ';
      content += (value === 1 ? suffix[0] : suffix[1]);
    }

    return content;
  };
}

function getWords(age: Age): [string, string] {
  if (age === Age["10a19a"]) {
    return ["adolescente", "adolescentes"];
  }

  return ["criança", "crianças"];
}

function getSuffixes(age: Age): [string, string] {
  if (age === Age["10a19a"]) {
    return ["acompanhado", "acompanhados"];
  }

  return ["acompanhada", "acompanhadas"];
}
