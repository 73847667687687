import { useMemo } from "preact/hooks";
import { MenuItemAge, MenuItemGender, MenuItemSkin } from "@_types/MenuItems";
import { Gender, Skin } from "@_types/Enums";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import capitalizeFirstLetter from "@lib/capitalizeFirstLetter";

export default function useHistoryDescription(
  childLabelFunction: ChildLabelFunction,
  age: MenuItemAge,
  gender: MenuItemGender,
  skin: MenuItemSkin
): string {
  return useMemo(() => {
    const content: string[] = [];
    content.push(`${capitalizeFirstLetter(childLabelFunction(2, false, false))} ${age.text}`);

    if (gender) {
      if (gender.value === Gender.all) {
        content.push("Todos os sexos");
      } else if (gender.value === Gender.m) {
        content.push("Sexo masculino");
      } else if (gender.value === Gender.f) {
        content.push("Sexo feminino");
      }
    }

    if (skin) {
      if (skin.value === Skin.all) {
        content.push("Todas as raças/cores");
      } else {
        content.push(`Raça ${skin.text.toLowerCase()}`);
      }
    }

    return content.join(" - ");
  }, [childLabelFunction, age, gender, skin]);
}
