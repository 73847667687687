import {useCallback, useEffect, useState} from "preact/hooks";
import logWarn from "@lib/logWarn";

declare global {
  interface Navigator { canShare?: (data: any) => boolean ; }
}

type DataToShare = {
  files?: File[],
  title?: string,
  text?: string,
  url?: string
}

export default function useDesiderataShareApi(useImage: boolean, useTextWithUrl: boolean, useText: boolean, useUrl: boolean): (e: Event) => void {
  const [blobImage, setBlobImage] = useState<Blob | null>(null);

  useEffect(() => {
    if(blobImage !== null) {
      return;
    }

    fetch("https://panorama.obesidadeinfantil.org.br/assets/social-media-rectangle.png").then((res) => {
      res.blob().then((blobImage) => {
        setBlobImage(blobImage);
      });
    });

  }, [blobImage, setBlobImage]);

  return useCallback((e: Event) => {
    if (!navigator.share || blobImage === null) {
      return true;
    }

    e.preventDefault();

    const data: DataToShare = {
      files: [
        new File([blobImage], "mobilize.png", {
          type: blobImage.type
        })
      ],
      text: "As crianças merecem opções nutritivas que contribuam para o desenvolvimento saudável e um futuro promissor.\n\nCompartilhe dessa causa e lute por um ambiente de estudo mais saudável para as crianças de sua cidade. 🥦🍎",
      url: "https://panorama.obesidadeinfantil.org.br/"
    };

    const dataToShare: DataToShare = {};
    if(useImage) {
      dataToShare.files = data.files;
    }

    if(useTextWithUrl) {
      dataToShare.text = `${data.text}\n\n${data.url}`;
    } else {
      if(useText) {
        dataToShare.text = data.text;
      }

      if(useUrl) {
        dataToShare.url = data.url;
      }
    }

    let _data: DataToShare = dataToShare;
    if(navigator.canShare) {
      if(!navigator.canShare(_data)) {
        _data = {url: data.url};
      }
    }

    navigator
        .share(_data)
        .then(() => true)
        .catch((e) => logWarn(e));

  }, [blobImage]);
}