import { Local } from "@_types/Local";

const states: Local[] = [
  { ibge: 12, name: "Acre", abbr: "AC", preposition: "no", imgHeight: 22 },
  { ibge: 27, name: "Alagoas", abbr: "AL", preposition: "em", imgHeight: 21 },
  { ibge: 16, name: "Amapá", abbr: "AP", preposition: "no", imgHeight: 23 },
  { ibge: 13, name: "Amazonas", abbr: "AM", preposition: "no", imgHeight: 22 },
  { ibge: 29, name: "Bahia", abbr: "BA", preposition: "na", imgHeight: 21 },
  { ibge: 23, name: "Ceará", abbr: "CE", preposition: "no", imgHeight: 22 },
  { ibge: 53, name: "Distrito Federal", abbr: "DF", preposition: "no", imgHeight: 22 },
  { ibge: 32, name: "Espírito Santo", abbr: "ES", preposition: "no", imgHeight: 22 },
  { ibge: 52, name: "Goiás", abbr: "GO", preposition: "em", imgHeight: 22 },
  { ibge: 21, name: "Maranhão", abbr: "MA", preposition: "no", imgHeight: 21 },
  { ibge: 51, name: "Mato Grosso", abbr: "MT", preposition: "no", imgHeight: 22 },
  { ibge: 50, name: "Mato Grosso Do Sul", abbr: "MS", preposition: "no", imgHeight: 22 },
  { ibge: 31, name: "Minas Gerais", abbr: "MG", preposition: "em", imgHeight: 22 },
  { ibge: 15, name: "Pará", abbr: "PA", preposition: "no", imgHeight: 21 },
  { ibge: 25, name: "Paraíba", abbr: "PB", preposition: "na", imgHeight: 22 },
  { ibge: 41, name: "Paraná", abbr: "PR", preposition: "no", imgHeight: 22 },
  { ibge: 26, name: "Pernambuco", abbr: "PE", preposition: "em", imgHeight: 22 },
  { ibge: 22, name: "Piauí", abbr: "PI", preposition: "no", imgHeight: 21 },
  { ibge: 33, name: "Rio De Janeiro", abbr: "RJ", preposition: "no", imgHeight: 22 },
  { ibge: 24, name: "Rio Grande Do Norte", abbr: "RN", preposition: "no", imgHeight: 21 },
  { ibge: 43, name: "Rio Grande Do Sul", abbr: "RS", preposition: "no", imgHeight: 22 },
  { ibge: 11, name: "Rondônia", abbr: "RO", preposition: "em", imgHeight: 22 },
  { ibge: 14, name: "Roraima", abbr: "RR", preposition: "em", imgHeight: 21 },
  { ibge: 42, name: "Santa Catarina", abbr: "SC", preposition: "em", imgHeight: 23 },
  { ibge: 35, name: "São Paulo", abbr: "SP", preposition: "em", imgHeight: 20 },
  { ibge: 28, name: "Sergipe", abbr: "SE", preposition: "em", imgHeight: 22 },
  { ibge: 17, name: "Tocantins", abbr: "TO", preposition: "em", imgHeight: 22 }
];

states.forEach((item: Local) => {
  item.search_name = item.name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
});

export default states;
