import { NutritionClass, NutritionClassData } from "@_types/NutritionClass";
import { Age, NutritionClassID } from "@_types/Enums";

export function filterByAge(classesData: NutritionClassData[], age: Age): NutritionClassData[] {
  return classesData.filter((item) => item.a === age);
}

type IndexedNutritionClasses = Record<NutritionClassID, NutritionClass>;

export function indexByClassId(classes: NutritionClass[]): IndexedNutritionClasses {
  const indexed = {} as IndexedNutritionClasses;
  classes.forEach((item: NutritionClass) => (indexed[item.id] = item));
  return indexed;
}

export function filterOverweight(classes: NutritionClass[]): NutritionClass[] {
  return classes.filter((item: NutritionClass) => {
    return (
      item.id === NutritionClassID.overweight0 ||
      item.id === NutritionClassID.overweight1 ||
      item.id === NutritionClassID.overweight2
    );
  });
}

export function filterUnderweight(classes: NutritionClass[]): NutritionClass[] {
  return classes.filter((item: NutritionClass) => {
    return item.id === NutritionClassID.underweight0 || item.id === NutritionClassID.underweight1;
  });
}
