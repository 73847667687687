import { h, JSX } from "preact";
import ButtonIcon from "@components/ButtonIcon";
import MobilizeBannerTag from "@components/MobilizeBannerTag";

type Props = {
  onClick: () => void;
};

export default function Banner(props: Props): JSX.Element {
  const { onClick } = props;

  return (
    <div class={"container"}>
      <div class={"banner"}>
        <div>
          <img src={"/assets/img/mobilize/mobilize-cloud-367x218.png"} width={367} height={218} />
        </div>
        <div>
          <div>
            <h1>Faça a diferença! Apoie a saúde alimentar nas escolas</h1>
            <p>
              Faça parte da iniciativa por uma alimentação saudável para crianças!
            </p>
            <MobilizeBannerTag />
            <strong>Tenha acesso ao nosso guia completo e encaminhe o projeto de lei recomendado aos vereadores e
              deputados da sua cidade ou estado.</strong>
          </div>
          <ButtonIcon label={"Quero apoiar a saúde e o bem-estar nas escolas"} icon={"paperplane"} onClick={onClick}
                      theme={"mobilize-primary"} extraCssClass={"display-flex-only-normal"}/>
          <ButtonIcon label={"Quero apoiar a saúde nas escolas"} icon={"paperplane"} onClick={onClick}
                      theme={"mobilize-primary"} extraCssClass={"display-flex-only-mobile"}/>
        </div>
      </div>
    </div>
  );
}
