import { h, JSX, RefObject } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import useSessionStorage from "@hooks/useSessionStorage";
import usePreventDefault from "@hooks/usePreventDefault";
import SvgIcon from "@components/SvgIcon";
import ButtonIcon from "@components/ButtonIcon";

type Props = {
  onClick: () => void;
};

export default function Buoyant(props: Props): JSX.Element {
  /*
   * Utilizamos a sessão
   * para armanezar se o flutuante foi fechado pelo usuário.
   * Quando uma nova aba é aberta o valor é perdido.
   */
  const [isClosed, setClosed] = useSessionStorage<boolean>("mobilize-fechado", false);

  /*
   * Quem controla se o elemento deve ou não ser exibido
   * é a posição do Scroll. Claro que se ele já foi fechado, nunca é exibido
   * A função aceita o y minimo e a distância máxima do fundo em que o elemento aparece.
   */
  const visible = useVisibleStateFromMinMaxScrollPosition(isClosed, 10, 710);

  /*
   * Como ainda não utilizamos nada específico para animações
   * esse hook faz o controle "manual" da animação, adicionando a classe correta e removendo o elemento após um tempo
   */
  const containerRef = useBuoyantContainerAnimation(visible);

  /*
   * Inicializa callbacks do componente, tanto de call to action como e fechar.
   */
  const onClickCallback = usePreventDefault(props.onClick);
  const closeCallback = usePreventDefault(() => setClosed(true));

  return (
    <div ref={containerRef} class={"buoyant-container"}>
      <div class={"buoyant buoyant-desktop"}>
        <div>
          <img src={"/assets/img/mobilize/mobilize-cloud-197x94.png"} width={197} height={94} />
          <span>Faça a diferença! Apoie a saúde alimentar nas escolas</span>
        </div>
        <ButtonIcon label={"Mobilize"} icon={"paperplane"} onClick={props.onClick} theme={"mobilize-primary"} />
        <BuoyantClose callback={closeCallback} />
      </div>

      <div class={"buoyant buoyant-mobile"}>
        <a href={"#"} onClick={onClickCallback}>
          <img src={"/assets/img/mobilize/mobilize-cloud-128x76.png"} width={128} height={76} />
        </a>
        <a onClick={onClickCallback}>
          <span>Faça a diferença!<br />Apoie a saúde alimentar nas escolas</span>
        </a>
        <BuoyantClose callback={closeCallback} />
      </div>
    </div>
  );
}

type CloseIconProps = {
  callback: (e: Event) => void;
};

function BuoyantClose({ callback }: CloseIconProps): JSX.Element {
  return (
    <a href={"#"} class={"buoyant-close"} onClick={callback}>
      <SvgIcon icon={"close"} />
    </a>
  );
}

function useVisibleStateFromMinMaxScrollPosition(
  isClosed: boolean,
  distanceFromTop: number,
  distanceFromBottom: number
): boolean {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isClosed && visible) {
      setVisible(false);
    }
  }, [isClosed, visible, setVisible]);

  const handleScroll = useCallback(() => {
    if (!window || !document) {
      return;
    }

    if (isClosed) {
      return;
    }

    const scrollY = window.pageYOffset;
    const minScrollY = distanceFromTop;
    const maxScrollY = document.body.offsetHeight - distanceFromBottom - window.innerHeight;

    if (scrollY >= minScrollY && scrollY <= maxScrollY && !visible) {
      setVisible(true);
      return;
    }

    if ((scrollY < minScrollY || scrollY > maxScrollY) && visible) {
      setVisible(false);
      return;
    }
  }, [visible, isClosed, setVisible, distanceFromBottom, distanceFromTop]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return visible;
}

function useBuoyantContainerAnimation(visible: boolean): RefObject<HTMLDivElement> {
  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // launch animation to display ...
    if (visible) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      containerRef.current.style.display = "block";
      containerRef.current.className = "buoyant-container-show-animation";
      return;
    }

    // launch animation to hide IF it was visible to avoid launch this animation on initial state ...
    const hasShowAnimation: boolean = containerRef.current.className.includes("show-animation");
    if (!visible && hasShowAnimation) {
      containerRef.current.className = "buoyant-container-hide-animation";

      timeoutRef.current = setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.style.display = "none";
        }
      }, 440);

      return;
    }
  }, [visible]);

  return containerRef;
}
