import { useCallback, useState } from "preact/hooks";

export default function useSearch(): [string, (e: Event) => void] {
  const [search, setSearch] = useState<string>("");
  const onInputSearch = useCallback(
    (e: Event) => {
      if (e.target instanceof HTMLInputElement) {
        setSearch(e.target.value);
      }
    },
    [setSearch]
  );

  return [search, onInputSearch];
}
