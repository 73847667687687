import { useCallback, useEffect } from "preact/hooks";
import { RefObject } from "preact";

export default function useDocumentListenerToCloseLayersAfterClick(
  isOpen: boolean,
  toggleOpen: () => void,
  refDropdown: RefObject<HTMLDivElement>
): void {
  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (!isOpen) {
        return;
      }

      if (!(event.target instanceof HTMLElement) || !(refDropdown.current instanceof HTMLDivElement)) {
        return;
      }

      const clickInDropdownElement = refDropdown.current.contains(event.target) || refDropdown.current === event.target;
      if (clickInDropdownElement) {
        return;
      }

      toggleOpen();
    },
    [isOpen, toggleOpen, refDropdown]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [handleDocumentClick]);
}
