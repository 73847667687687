import { NutritionClass, NutritionClassData } from "@_types/NutritionClass";
import { Age, Gender, NutritionClassID, Skin } from "@_types/Enums";
import largestRemainderRound from "../scripts/Functions/math/largestRemainderRound";

export function aggregateExtraNutritionClasses(items: NutritionClassData[]): NutritionClassData[] {
  //use all items to calc
  const items0To19 = calculateNewItems(Age["0a19a"], items);

  //use only a subset to calc
  const itemsFilteredToCalc0To10 = items.filter((item) => item.a === Age["0m5a"] || item.a === Age["5a10a"]);
  const items0To10 = calculateNewItems(Age["0a10a"], itemsFilteredToCalc0To10);

  //add to main group and return
  items.push(...items0To19, ...items0To10);
  return items;
}

function calculateNewItems(age: Age, items: NutritionClassData[]): NutritionClassData[] {
  const newItems: Record<string, NutritionClassData> = {};
  items.forEach((item: NutritionClassData) => {
    const key = `${item.y}-${item.g}-${item.s}`;
    if (!(key in newItems)) {
      newItems[key] = createEmptyNutritionClassData(age, item.y, item.g, item.s);
    }

    const newItem = newItems[key];

    // increment total of children...
    newItem.children += item.children;

    // increment estimated population...
    if (item.estPop !== undefined) {
      if (newItem.estPop === undefined) {
        newItem.estPop = 0;
      }
      newItem.estPop += item.estPop;
    }

    // increment children in each class item ...
    item.classes.forEach((classItem: NutritionClass) => {
      const newClassItem = newItem.classes.find((newClassItem) => newClassItem.id === classItem.id);
      if (newClassItem) {
        newClassItem.c += classItem.c;
      }
    });
  });

  const newItemsArray = Object.values(newItems);
  processToAddPercentsToItems(newItemsArray);
  return newItemsArray;
}

function processToAddPercentsToItems(newItemsArray: NutritionClassData[]) {
  newItemsArray.forEach((newItem) => {
    const percents: number[] = [];

    //process simple percents
    newItem.classes.forEach((classItem) => {
      const percent = (classItem.c / newItem.children) * 100;
      percents.push(percent);
      classItem.p = parseFloat(percent.toFixed(3));
    });

    //process perfectly rounded percents
    const percentsRounded: number[] = largestRemainderRound(percents);
    newItem.classes.forEach((classItem, index) => {
      classItem.ppr = percentsRounded[index];
    });
  });
}

function createEmptyNutritionClassData(
  age: Age,
  year: number,
  gender: Gender = Gender.all,
  skin: Skin = Skin.all
): NutritionClassData {
  return {
    y: year,
    a: age,
    g: gender,
    s: skin,
    children: 0,
    classes: [
      createEmptyNutritionClass(NutritionClassID.underweight0),
      createEmptyNutritionClass(NutritionClassID.underweight1),
      createEmptyNutritionClass(NutritionClassID.normal),
      createEmptyNutritionClass(NutritionClassID.overweight0),
      createEmptyNutritionClass(NutritionClassID.overweight1),
      createEmptyNutritionClass(NutritionClassID.overweight2)
    ]
  };
}

function createEmptyNutritionClass(classId: NutritionClassID): NutritionClass {
  return {
    id: classId,
    c: 0,
    p: 0,
    ppr: 0
  };
}
