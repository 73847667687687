import { Local } from "@_types/Local";

export default function formatLocalName(local: Local): string {
  if (local.abbr === "BR") {
    return local.name;
  }

  let prefix = "";
  if (local.name === "Rio De Janeiro" && local.preposition === undefined) {
    prefix = "Município do ";
  }

  if (local.name === "São Paulo" && local.preposition === undefined) {
    prefix = "Município de ";
  }

  if (local.abbr === "") {
    return `${prefix}${local.name}`;
  }

  return `${prefix}${local.name} - ${local.abbr.toUpperCase()}`;
}
