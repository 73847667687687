import { h, JSX } from "preact";
import textNoteAboutTotalOfChildrenInSkinFilters from "@texts/textNoteAboutTotalOfChildrenInSkinFilters";
import textNoteAboutYellowSkinProblem from "@texts/textNoteAboutYellowSkinProblem";
import textNoteAboutSkinDataFrom2022 from "@texts/textNoteAboutSkinDataFrom2022";

export default function SectionNutritionTableNotes(): JSX.Element {
  return <p>
    *¹ {textNoteAboutTotalOfChildrenInSkinFilters}<br />
    <br />
    *² {textNoteAboutYellowSkinProblem}<br />
    <br />
    *³ {textNoteAboutSkinDataFrom2022}
  </p>
}