import { useEffect, useState } from "preact/hooks";
import logWarn from "@lib/logWarn";

export type ApiUserData = {
  toEmail: string;
  toName: string;
  ibge: string;
} | null;

export enum ApiStatus {
  Waiting,
  Loading,
  Success,
  Error
}

export default function useApi(userData: ApiUserData): [ApiStatus, string] {
  const [apiStatus, setApiStatus] = useState<ApiStatus>(ApiStatus.Waiting);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if(userData === null) {
      return;
    }

    setApiStatus(ApiStatus.Loading);
    fetch(`${process.env.URL_API}email/mobilize/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...userData })
    })
      .then((res) => {
        if(res.status === 200) {
          setApiStatus(ApiStatus.Success);
        } else if(res.status === 400) {
          res.json().then((data) => setErrorMessage(data.error));
          setApiStatus(ApiStatus.Error);
        } else {
          setErrorMessage("Erro na comunicação com o servidor, tente novamente mais tarde.");
          setApiStatus(ApiStatus.Error);
        }

        return true;
      })
      .catch((reason) => {
        logWarn('Erro Api', reason);
        setApiStatus(ApiStatus.Error);
        setErrorMessage("Erro na comunicação com o servidor, tente novamente mais tarde.");
      })

  }, [setApiStatus, userData]);

  return [apiStatus, errorMessage];
}
