import { h, JSX } from "preact";
import { StateUpdater, useCallback, useMemo } from "preact/hooks";
import { Local } from "@_types/Local";
import { MenuItem, MenuItems, MenuItemUpdater } from "@_types/MenuItems";
import Dropdown from "@components/Dropdown";
import formatLocalName from "@lib/formatLocalName";
import {DropdownThemes} from "@components/Dropdown/Dropdown";

type Props = {
  local: Local | null;
  setLocal: StateUpdater<Local | null>;
  locals: Local[];
  label: string;
  displayFlag?: boolean;
  theme?: DropdownThemes
};

type MenuItemLocal = MenuItem<Local> | null;

export default function DropdownLocals(props: Props): JSX.Element {
  const displayFlag = props.displayFlag || false;
  const { local, setLocal, locals, label, theme } = props;
  const items = useLocalsAsMenuItems(locals, displayFlag);
  const [itemSelected, setItemSelected] = useControlledMenuItem(local, setLocal, displayFlag);
  return <Dropdown label={label} items={items} selected={itemSelected} updater={setItemSelected} theme={theme} />;
}

function useLocalsAsMenuItems(locals: Local[], displayFlag: boolean): MenuItems<Local> {
  return useMemo(() => locals.map((i) => localToMenuItem(i, displayFlag)), [locals, displayFlag]);
}

function localToMenuItem(item: Local, displayFlag: boolean): MenuItem<Local> {
  const isRegion = [2, 3, 4, 5, 6].includes(item.ibge);
  return {
    value: item.ibge,
    text: formatLocalName(item),
    searchText: item.search_name,
    img: displayFlag && !isRegion ? `/assets/img/flags/${item.abbr.toLowerCase()}.png` : null,
    imgHeight: item.imgHeight ?? null,
    source: item
  };
}

function useControlledMenuItem(
  local: Local | null,
  setLocal: StateUpdater<Local | null>,
  displayFlag: boolean
): [MenuItemLocal, MenuItemUpdater<Local>] {
  return [
    useMemo(() => (local ? localToMenuItem(local, displayFlag) : null), [local, displayFlag]),
    useCallback((item: MenuItemLocal) => item?.source && setLocal(item.source), [setLocal])
  ];
}
