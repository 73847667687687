import { NutritionClassColumn } from "@_types/NutritionClass";
import { Age, NutritionClassID } from "@_types/Enums";
import getNutritionClassOverweightColumns from "@lib/getNutritionClassOverweightColumns";

export default function getNutritionClassColumns(age: Age): NutritionClassColumn[] {
  const columns = [
    { classId: NutritionClassID.underweight0, title: "Magreza Acentuada", key: "underweight0" },
    { classId: NutritionClassID.underweight1, title: "Magreza", key: "underweight1" },
    { classId: NutritionClassID.normal, title: "Peso Adequado (Eutrofia)", key: "normal" }
  ];

  columns.push(...getNutritionClassOverweightColumns(age));

  return columns;
}
