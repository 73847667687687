import { h, JSX } from "preact";
import BoxAlert from "@components/BoxAlert";

type Props = {
  title: string;
  description: string;
};

export default function BoxAlertContainer(props: Props): JSX.Element {
  return (
    <div className={"container"}>
      <BoxAlert {...props} />
    </div>
  );
}
