import { StateUpdater, useCallback, useState } from "preact/hooks";
import logWarn from "@lib/logWarn";

//based on https://usehooks-ts.com/react-hook/use-session-storage
export default function useSessionStorage<T>(keySuffix: string, initialValue: T): [T, StateUpdater<T>] {
  const key = `desiderata-notificador-${keySuffix}`;

  // Get from session storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined"
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      logWarn(`Erro na leitura da chave “${key}” no armazenamento da sessão:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // ... persists the new value to sessionStorage.
  const setValue: StateUpdater<T> = useCallback((value) => {
    // Prevent build error "window is undefined"
    if (typeof window === "undefined") {
      logWarn(`Tried setting sessionStorage key “${key}” even though environment is not a client`);
      return;
    }

    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (error) {
      logWarn(`Erro aom tentar armazenar chave “${key}” no armazenamento da sessão:`, error);
    }
  }, [setStoredValue]);

  return [storedValue, setValue];
}