import { useCallback } from "preact/hooks";

export default function useOnInput(callback: (value: string) => any): (e: Event) => void {
  return useCallback(
    (e: Event) => {
      if (e.target instanceof HTMLInputElement) {
        callback(e.target.value);
      }
    },
    [callback]
  );
}
