import {Fragment, h, JSX} from "preact";

function textNoteAboutYellowSkinProblem(): JSX.Element {
  return <Fragment>
    Para o IBGE, a raça/cor "amarela" refere-se a pessoas que tenham origens em países do extremo oriente, como Japão, China, Coréia do Sul e do Norte. No SISVAN, o total de crianças acompanhadas de raça/cor amarela é muito superior à população de mesma raça/cor verificada pelo Censo Demográfico 2022.<br />
    Por esse motivo, o percentual de cobertura desta raça/cor apresenta valores discrepantes.<br />
    O quesito raça/cor baseia-se na autodeclaração, no entanto é preciso ter em vista que em consequência de um processo histórico de tentativa de invisibilização da população negra no Brasil, muitas pessoas pardas e pretas não se reconhecem como negras, diluindo-se em outras categorias, como a amarela.
  </Fragment>
}

export default textNoteAboutYellowSkinProblem();