import { Fragment, h, JSX } from "preact";
import { MenuItemAge } from "@_types/MenuItems";
import { NutritionClass, NutritionClassData, NutritionClassFormatted } from "@_types/NutritionClass";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import BoxHighlightNumber from "@components/BoxHighlightNumber";
import getNutritionClassOverweightColumns from "@lib/getNutritionClassOverweightColumns";
import formatNutritionClass from "@lib/formatNutritionClass";
import { reduceToNormalweight, reduceToOverweight, reduceToUnderweight } from "@lib/reducersNutritionClassData";
import BoxHistory, { HistoryData } from "@components/BoxHistory";
import ButtonHistory from "@components/ButtonHistory";
import { useCallback, useEffect, useState } from "preact/hooks";
import ButtonIcon from "@components/ButtonIcon";
import partialYear from "@data/partialYear";
import {AppSection} from "@_types/Enums";

type Props = {
  openModal: () => void;
  age: MenuItemAge;
  nutritionClassData: NutritionClassData;
  historyData: NutritionClassData[];
  historyDescription: string;
  skinLabel: string;
  childLabelFunc: ChildLabelFunction;
  svgIcons: JSX.Element[];
  localLabel: string;
  year: number;
};

let permanentHistoryIdSelected: string | null = null;

export default function SectionNutritionClassesSimplified(props: Props): JSX.Element {
  const {
    openModal,
    historyData,
    historyDescription,
    nutritionClassData,
    skinLabel,
    childLabelFunc,
    age,
    localLabel,
    year
  } = props;

  const underweightItem = formatNutritionClass(reduceToUnderweight(nutritionClassData));
  const normalItem = formatNutritionClass(reduceToNormalweight(nutritionClassData));
  const overweightItem = formatNutritionClass(reduceToOverweight(nutritionClassData));

  const columns = getNutritionClassOverweightColumns(age.value);
  const overweightSubtitle = columns.map((column) => column.title).join(" + ");

  const items: [string, string, NutritionClassFormatted, string, string?][] = [
    ["Desnutrição", "Magreza Acentuada + Magreza", underweightItem, "risco de desnutrição", "theme-underweight"],
    ["Peso Adequado", "Eutrofia", normalItem, "IMC adequado para a sua idade"],
    ["Excesso de Peso", overweightSubtitle, overweightItem, "excesso de peso", "theme-overweight"]
  ];

  const [historyIdSelected, setHistoryIdSelected] = useState<string | null>(permanentHistoryIdSelected);
  const onClickHistoryButton = useCallback(
    (id: string) => setHistoryIdSelected(id !== historyIdSelected ? id : null),
    [historyIdSelected, setHistoryIdSelected]
  );
  const onCloseClick = useCallback(() => setHistoryIdSelected(null), [setHistoryIdSelected]);
  useEffect(() => {
    permanentHistoryIdSelected = historyIdSelected;
  }, [historyIdSelected]);

  let historyContent: JSX.Element = <Fragment />;
  if (historyIdSelected) {
    const itemHistory = items[parseInt(historyIdSelected, 10)];
    const historyDataFormatted: HistoryData = historyData.map((item: NutritionClassData) => {
      let i: NutritionClass;
      if (historyIdSelected === "0") {
        i = reduceToUnderweight(item);
      } else if (historyIdSelected === "1") {
        i = reduceToNormalweight(item);
      } else {
        i = reduceToOverweight(item);
      }
      return {
        year: item.y,
        number: i.c,
        percent: i.p,
        total: item.children,
        isPartial: item.y === partialYear,
        isCurrent: item.y === year
      };
    });

    historyContent = (
      <BoxHistory
        title={`Histórico - ${itemHistory[0]}`}
        description={historyDescription}
        historyData={historyDataFormatted}
        childLabelFunction={childLabelFunc}
        onCloseClick={onCloseClick}
        year={year}
        appSectionType={AppSection.NUTRITION_CLASS}
      />
    );
  }

  const classHistoryOpen = historyIdSelected ? "mode-history-open" : "";

  return (
    <Fragment>
      <div className={`nutrition-classes ${classHistoryOpen} | flex flex-h-space-between margin-vertical`}>
        {items.map((item, index) => {
          const id = `${index}`;
          const title = item[0];
          const subtitle = item[1];
          const itemClass = item[2];
          const descSuffix = item[3];
          const extraClass = item[4] ?? "";
          const childLabel = childLabelFunc(itemClass.children);

          let numberLabel, mainValue, numberType: "absolute" | "percentRounded";
          if (nutritionClassData.children < 100) {
            numberLabel = childLabel;
            mainValue = itemClass.children;
            numberType = "absolute";
          } else {
            numberLabel = `${itemClass.percentPerfectRounded} em cada 100 ${childLabelFunc(0)}`;
            mainValue = itemClass.percentPerfectRounded;
            numberType = "percentRounded";
          }

          if (historyIdSelected && historyIdSelected !== `${index}`) {
            return null;
          }

          return (
            <article key={item} className={extraClass}>
              <div>
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
              </div>
              <BoxHighlightNumber value={mainValue} type={numberType}>
                {numberLabel} {skinLabel} {age.text}.
              </BoxHighlightNumber>
              {itemClass.children > 0 && (
                <div>
                  <p>
                    De acordo com o SISVAN, em {year} foram registrados <strong>{itemClass.childrenFormatted}</strong>{" "}
                    {childLabel} com {descSuffix} - {localLabel}.
                  </p>
                </div>
              )}
              <ButtonHistory id={id} isOpen={historyIdSelected === id} theme={"light"} onClick={onClickHistoryButton} />
              {index === 2 && (
                <ButtonIcon
                  label={"Mobilize seu município"}
                  icon={"paperplane"}
                  onClick={openModal}
                  theme={"mobilize-light"}
                />
              )}
              {index !== 2 && <div class={"spacer"} />}
            </article>
          );
        })}

        {historyContent}
      </div>
    </Fragment>
  );
}
