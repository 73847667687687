import { RefObject } from "preact";
import { useLayoutEffect } from "preact/hooks";

export default function useFocusOnOpen(isOpen: boolean, inputRef: RefObject<HTMLInputElement>): void {
  useLayoutEffect(() => {
    if (isOpen && inputRef.current instanceof HTMLInputElement) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpen]);
}
