import { MenuItemSkin } from "@_types/MenuItems";
import { Skin } from "@_types/Enums";

const skinMenuItems: MenuItemSkin[] = [
  { value: Skin.all, text: "Todas" },
  { value: Skin.black, text: "Preta" },
  { value: Skin.brown, text: "Parda" },
  { value: Skin.white, text: "Branca" },
  { value: Skin.indian, text: "Indígena" },
  { value: Skin.yellow, text: "Amarela" }
];

export default skinMenuItems;
