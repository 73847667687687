import { MenuItemAge } from "@_types/MenuItems";
import { Age } from "@_types/Enums";

const foodAgeMenuItems: MenuItemAge[] = [
  { value: Age["0m6m"], text: "de 0 até 6 meses" },
  { value: Age["6m2a"], text: "de 6 meses até 2 anos" },
  { value: Age["2a5a"], text: "de 2 até 4 anos" },
  { value: Age["5a10a"], text: "de 5 até 9 anos" },
  { value: Age["10a19a"], text: "de 10 até 19 anos" }
];

export default foodAgeMenuItems;
