import { h, JSX } from "preact";
import snarkdown from "snarkdown";
import { useMemo } from "preact/hooks";

type Props = {
  content: string;
};

export default function Markdown(props: Props): JSX.Element {
  const content = useMemo(() => {
    return props.content ? snarkdown(props.content) : "";
  }, [props.content]);

  /* eslint-disable-next-line react/no-danger */
  return <span dangerouslySetInnerHTML={{ __html: content }} />;
}
