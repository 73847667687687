import { ComponentChildren, h, JSX } from "preact";
import { useState } from "preact/hooks";
import { TooltipState } from "./Types";
import TooltipContext from "./TooltipContext";
import usePopper from "./usePopper";
import style from "./style.scss";

type Props = {
  children: ComponentChildren;
};

export default function TooltipProvider(props: Props): JSX.Element {
  const [tooltipState, setTooltipState] = useState<TooltipState>(null);
  const refTooltip = usePopper(tooltipState, 8, "top");

  return (
    <TooltipContext.Provider value={setTooltipState}>
      {props.children}

      <div
        className={`${style.overlay} ${style.tooltip}`}
        role="tooltip"
        ref={refTooltip}
        data-show={tooltipState ? 1 : 0}
      >
        <span>{tooltipState?.text}</span>
        <div className={style.arrow} data-popper-arrow="" />
      </div>
    </TooltipContext.Provider>
  );
}
