import { Fragment, h, JSX } from "preact";
import ButtonIcon, { ButtonIconSocial } from "@components/ButtonIcon";
import { useEffect, useRef, useState } from "preact/hooks";
import useDocumentListenerToCloseLayersAfterClick from "@hooks/useDocumentListenerToCloseLayersAfterClick";
import DropdownLocals from "@components/DropdownLocals";
import { Local } from "@_types/Local";
import useApi, { ApiStatus, ApiUserData } from "@hooks/useApi";
import useSessionStorage from "@hooks/useSessionStorage";
import useOnInput from "@hooks/useOnInput";
import { useCopyToClipboard } from "@hooks/useCopyToClipboard";
import useDesiderataShareApi from "@hooks/useDesiderataShareApi";
import MobilizeBannerTag from "@components/MobilizeBannerTag";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  cities: Local[];
  position: ModalActiveScreen;
  setPosition: (value: ModalActiveScreen) => void;
  local?: Local;
};

export type ModalActiveScreen = 1 | 2 | 3 | null;

export default function ModalMobilize(props: Props): JSX.Element {
  const { isOpen, onClose, cities, position, setPosition, local } = props;
  const refDiv = useRef<HTMLDivElement>(null);
  const _onClose = () => {
    onClose();
  };
  useDocumentListenerToCloseLayersAfterClick(isOpen, _onClose, refDiv);

  return (
    <div class={`modal ${isOpen ? "" : "modal-closed"}`}>
      <div class={`modal-content ${isOpen ? "modal-appear" : ""}`} ref={refDiv}>
        <div class={"modal-mobilize"}>
          {position === 1 && (
            <ContentOne onClose={_onClose} cities={cities} onNext={() => setPosition(2)} local={local} />
          )}
          {position === 2 && (
            <ContentTwo onClose={_onClose} cities={cities} onNext={() => setPosition(3)} local={local} />
          )}
          {position === 3 && <ContentThree onClose={_onClose} cities={cities} onNext={_onClose} local={local} />}
        </div>
      </div>
    </div>
  );
}

type ContentProps = {
  onClose: () => void;
  onNext: () => void;
  cities: Local[];
  local?: Local;
};

function ContentOne(contentProps: ContentProps): JSX.Element {
  const { onClose, onNext } = contentProps;

  return (
    <Fragment>
      <div>
        <img src={"/assets/img/mobilize/mobilize-cloud-367x218.png"} width={367} height={218} />
      </div>
      <div>
        <h1>Faça a diferença! Apoie a saúde alimentar nas escolas</h1>
        <p>Faça parte da iniciativa por uma alimentação saudável para crianças!</p>
        <MobilizeBannerTag />
        <strong>
          Tenha acesso ao nosso guia completo e encaminhe o projeto de lei recomendado aos vereadores e deputados da sua
          cidade ou estado.
        </strong>
        <div class={"mobilize-modal-action-buttons"}>
          <ButtonIcon label={"Voltar ao panorama"} icon={"arrow"} onClick={() => onClose()} theme={"mobilize-light"} />
          <ButtonIcon
            label={"Quero apoiar a saúde nas escolas"}
            icon={"paperplane"}
            onClick={() => onNext()}
            theme={"mobilize-primary"}
          />
        </div>
      </div>
    </Fragment>
  );
}

function ContentTwo(contentProps: ContentProps): JSX.Element {
  const { onClose, onNext, local, cities } = contentProps;

  const formRef = useRef<HTMLFormElement>(null);

  /* Salva os dados dos usuários na sessão */
  const [name, setName] = useSessionStorage<string>("mobilize-name", "");
  const [email, setEmail] = useSessionStorage<string>("mobilize-email", "");
  const [selectedCity, setSelectedCity] = useSessionStorage<Local | null>("mobilize-city", null);

  /* Encapsula callbacks para uso no onInput por causa de um erro de tipos do Preact com o text input */
  const setNameOnInput = useOnInput(setName);
  const setEmailOnInput = useOnInput(setEmail);

  /* Dados para uso da API */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userData, setUserData] = useState<ApiUserData>(null);
  const [apiStatus, apiErrorMessage] = useApi(userData);

  useEffect(() => {
    if (local && local.ibge.toString().length === 6) {
      setSelectedCity(local);
    }
  }, [setSelectedCity, local]);

  useEffect(() => {
    setErrorMessage(null);
  }, [setErrorMessage, selectedCity]);

  const _onNext = () => {
    if (formRef.current === null) {
      return;
    }

    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      setErrorMessage("É necessário informar um nome e email válidos");
      return;
    }

    if (selectedCity === null) {
      setErrorMessage("É obrigatório escolher um município");
      return;
    }

    setUserData({
      toName: name,
      toEmail: email,
      ibge: selectedCity.ibge.toString(10)
    });
  };

  useEffect(() => {
    if (apiStatus === ApiStatus.Success) {
      onNext();
    }

    if (apiStatus === ApiStatus.Error) {
      setErrorMessage(apiErrorMessage);
    }
  }, [apiStatus, onNext, setErrorMessage]);

  return (
    <Fragment>
      <div>
        <img
          src={"/assets/img/mobilize/mobilize-cloud-367x218.png"}
          width={367}
          height={218}
          class={"display-block-only-normal"}
        />
      </div>
      <div>
        <h1>Participe da mudança!</h1>
        <p>As informações do material são personalizadas para a sua região ou cidade.</p>
        <form ref={formRef}>
          <fieldset>
            <label>Selecione a sua cidade*:</label>
            <DropdownLocals
              locals={cities}
              local={selectedCity}
              setLocal={setSelectedCity}
              label={`Clique para escolher`}
              theme={"mobilize"}
            />
          </fieldset>
          <fieldset>
            <label>Digite seu nome completo*:</label>
            <input
              type="text"
              placeholder={"Digite seu nome completo"}
              required={true}
              onInput={setNameOnInput}
              value={name}
            />
          </fieldset>
          <fieldset>
            <label>Digite seu email*:</label>
            <input
              type="email"
              placeholder={"Digite o seu melhor e-mail"}
              required={true}
              onInput={setEmailOnInput}
              value={email}
            />
          </fieldset>
        </form>
        {errorMessage !== null && <p class={"mobilize-modal-error-message"}>{errorMessage}</p>}
        <div class={"mobilize-modal-action-buttons"}>
          <ButtonIcon
            label={"Voltar ao panorama"}
            icon={"arrow"}
            onClick={() => onClose()}
            theme={"mobilize-light"}
            disabled={apiStatus === ApiStatus.Loading}
          />
          <ButtonIcon
            label={apiStatus === ApiStatus.Loading ? "Enviando..." : "Enviar por e-mail"}
            icon={"check"}
            onClick={() => _onNext()}
            theme={"mobilize-primary"}
            disabled={apiStatus === ApiStatus.Loading}
          />
        </div>
      </div>
    </Fragment>
  );
}

function ContentThree(contentProps: ContentProps): JSX.Element {
  const { onClose } = contentProps;
  const [copiedContent, onClickCopyToClipboard] = useCopyToClipboard();
  const onClickInsta = useDesiderataShareApi(true, false, true, false);
  const onClickZap = useDesiderataShareApi(false, true, false, false);

  const desiderataUrl = "https://panorama.obesidadeinfantil.org.br/";
  const desiderataUrlEncoded = "https%3A%2F%2Fpanorama.obesidadeinfantil.org.br%2F";
  const urlInsta = "https://www.instagram.com/institutodesiderata/";
  const urlFace = `https://www.facebook.com/sharer/sharer.php?u=${desiderataUrlEncoded}`;
  const urlZap = `https://wa.me/?text=${desiderataUrlEncoded}`;
  const urlLinkedin = `https://www.linkedin.com/sharing/share-offsite/?url=${desiderataUrlEncoded}`;

  return (
    <Fragment>
      <div>
        <img src={"/assets/img/mobilize/mobilize-cloud-367x218.png"} width={367} height={218} />
      </div>
      <div>
        <h1>Obrigado por fazer parte!</h1>

        <p>As crianças merecem escolhas adequadas para o seu desenvolvimento saudável e um futuro promissor.</p>
        <strong>Compartilhe dessa causa e lute por um ambiente de estudo mais saudável para as crianças de sua cidade.</strong>

        <div class={"mobilize-modal-social-buttons"}>
          <ButtonIconSocial
            icon={"instagram"}
            onClick={onClickInsta}
            fallbackUrl={urlInsta}
            title={"Compartilhe no Instagram"}
          />
          <ButtonIconSocial
            icon={"facebook"}
            onClick={() => null}
            fallbackUrl={urlFace}
            title={"Compartilhe no Facebook"}
          />
          <ButtonIconSocial
            icon={"whatsapp"}
            onClick={onClickZap}
            fallbackUrl={urlZap}
            title={"Compartilhe no WhatsApp"}
          />
          <ButtonIconSocial
            icon={"linkedin"}
            onClick={() => null}
            fallbackUrl={urlLinkedin}
            title={"Compartilhe no LinkedIn"}
          />
          <ButtonIcon icon={"link"} onClick={() => onClickCopyToClipboard(desiderataUrl)} theme={"mobilize-light"} />
        </div>

        {copiedContent && <p>Endereço deste site copiado para a área de transferência.</p>}

        <div class={"mobilize-modal-action-buttons"}>
          <ButtonIcon
            label={"Voltar ao panorama"}
            icon={"arrow"}
            onClick={() => onClose()}
            theme={"mobilize-primary"}
          />
        </div>
      </div>
    </Fragment>
  );
}
