import { h, JSX } from "preact";

/*
 * Pequena tag de conteúdo a ser inserida no Banner para afirmar que RJ e Niterói já possuem projetos de lei
 */
export default function MobilizeBannerTag(): JSX.Element {
  return (
    <span className={"mobilize-banner-tag"}>
      <a
        href="https://www.obesidadeinfantil.org.br/post/rio-de-janeiro-aprova-lei-que-promove-alimenta%C3%A7%C3%A3o-adequada-e-saud%C3%A1vel-nas-escolas"
        target="_blank"
        rel={"noreferrer"}
        title={"Clique e saiba mais sobre o PL no Rio de Janeiro"}
      >
        Rio de Janeiro
      </a>{" "}
      e{" "}
      <a
        href="https://desiderata.org.br/niteroi-diz-sim-para-lei-3766-2023/"
        target="_blank"
        rel={"noreferrer"}
        title={"Clique e saiba mais sobre o PL em Niterói"}
      >
        Niterói
      </a>{" "}
      já possuem projetos de lei (PL)!
    </span>
  );
}
