import { Local } from "@_types/Local";

const regions: Local[] = [
  { ibge: 2, name: "Região Centro-Oeste", abbr: "", preposition: "na" },
  { ibge: 3, name: "Região Nordeste", abbr: "", preposition: "na" },
  { ibge: 4, name: "Região Norte", abbr: "", preposition: "na" },
  { ibge: 5, name: "Região Sudeste", abbr: "", preposition: "na" },
  { ibge: 6, name: "Região Sul", abbr: "", preposition: "na" }
];

export const ufByRegion: Record<number, string[]> = {
  2: ["MT", "MS", "GO", "DF"],
  3: ["MA", "PI", "CE", "PB", "PE", "AL", "SE", "BA", "RN"], // nordeste
  4: ["RR", "AP", "AM", "PA", "AC", "RO", "TO"],
  5: ["RJ", "SP", "ES", "MG"],
  6: ["PR", "SC", "RS"]
};

export default regions;
