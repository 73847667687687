import { MenuItemGender } from "@_types/MenuItems";
import { Gender } from "@_types/Enums";

const genderMenuItems: MenuItemGender[] = [
  { value: Gender.all, text: "Todos", svgIcons: ["boy-happy", "girl-happy"] },
  { value: Gender.m, text: "Masculino", svgIcons: ["boy-happy"] },
  { value: Gender.f, text: "Feminino", svgIcons: ["girl-happy"] }
];

export default genderMenuItems;
