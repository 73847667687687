import { useEffect, useState } from "preact/hooks";
import { Local } from "@_types/Local";

export default function useCitiesApi(): [boolean, Local[]] {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<Local[]>([]);

  useEffect(() => {
    const controller = new AbortController();
    fetch("/api/cities.json", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Accept: "application/json"
      }
    })
      .then((response) => {
        setLoading(false);

        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then(
        (response: Local[]) => {
          response.forEach((item: Local) => {
            item.search_name = item.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
          });
          setResult(response);
        },
        (error: Error) => console.error("Erro ao realizar a requisição", error)
      );

    return () => {
      controller.abort();
    };
  }, []);

  return [isLoading, result];
}
