import { MenuItemMode } from "@_types/MenuItems";
import { Mode } from "@_types/Enums";

const modeMenuItems: MenuItemMode[] = [
  { value: Mode.simplified, text: "Simplificado", svgIcons: ["chart-1"] },
  { value: Mode.complete, text: "Completo", svgIcons: ["chart-2"] },
  { value: Mode.analytical, text: "Analítico", svgIcons: ["chart-3"] },
  { value: Mode.historySimplified, text: "Histórico Simplificado", svgIcons: ["chart-3"] },
  { value: Mode.history, text: "Histórico Completo", svgIcons: ["chart-3"] }
];

export default modeMenuItems;
