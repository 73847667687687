import { MenuItem, MenuItemUpdater } from "@_types/MenuItems";
import { useCallback } from "preact/hooks";
import { h, JSX } from "preact";
import SvgIcons from "../SvgIcons";

type Props<T, K> = {
  item: MenuItem<T, K>;
  updater: MenuItemUpdater<T, K>;
  closer: () => void;
};

export default function Item<T, K>(props: Props<T, K>): JSX.Element {
  const { item, updater, closer } = props;
  const onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      updater(item);
      closer();
    },
    [updater, item, closer]
  );

  return (
    <a key={item.value} href={`#${item.value}`} onClick={onClick} className={item?.extraClass ?? ""}>
      <SvgIcons icons={item?.svgIcons ?? []} />
      {item.img ? <img src={`./${item.img}`} alt={"Bandeira"} width={32} height={item.imgHeight ?? 22} /> : ""}
      <span>{item.text}</span>
    </a>
  );
}
