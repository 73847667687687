import { h, JSX } from "preact";
import { SvgIconAvailable } from "@_types/Svg";

type Props = {
  icon: SvgIconAvailable;
};

export default function SvgIcon(props: Props): JSX.Element {
  return (
    <svg>
      <use xlinkHref={`#${props.icon}`} />
    </svg>
  );
}
