import { useState } from "preact/hooks";
import logWarn from "@lib/logWarn";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      logWarn("Clipboard não suportado");
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => { setCopiedText(null) }, 5000);
      return true;
    } catch (error) {
      logWarn("Cópia do conteúdo falhou", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}
