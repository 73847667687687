import { NutritionClassColumn } from "@_types/NutritionClass";
import { Age, NutritionClassID } from "@_types/Enums";

export default function getNutritionClassOverweightColumns(age: Age): NutritionClassColumn[] {
  if (age === Age["0m5a"]) {
    return [
      { classId: NutritionClassID.overweight0, title: "Risco de Sobrepeso", key: "overweight0" },
      { classId: NutritionClassID.overweight1, title: "Sobrepeso", key: "overweight1" },
      { classId: NutritionClassID.overweight2, title: "Obesidade", key: "overweight2" }
    ];
  }

  if (age === Age["0a19a"]) {
    return [
      { classId: NutritionClassID.overweight0, title: "Risco de Sobrepeso / Sobrepeso", key: "overweight0" },
      { classId: NutritionClassID.overweight1, title: "Sobrepeso / Obesidade", key: "overweight1" },
      { classId: NutritionClassID.overweight2, title: "Obesidade / Obesidade Grave", key: "overweight2" }
    ];
  }

  return [
    { classId: NutritionClassID.overweight0, title: "Sobrepeso", key: "overweight0" },
    { classId: NutritionClassID.overweight1, title: "Obesidade", key: "overweight1" },
    { classId: NutritionClassID.overweight2, title: "Obesidade Grave", key: "overweight2" }
  ];
}
