import { h, JSX } from "preact";
import { StateUpdater, useCallback, useEffect, useState } from "preact/hooks";
import { Local } from "@_types/Local";
import DropdownLocals from "@components/DropdownLocals";
import brazil from "@data/brazil";
import states from "@data/states";
import regions, { ufByRegion } from "@data/regions";
import formatNumber from "@lib/formatNumber";
import { MenuItem } from "@_types/MenuItems";
import Dropdown from "@components/Dropdown";

type Props = {
  localUpdater: StateUpdater<Local>;
  cities: Local[];
  year: number;
  yearUpdater: StateUpdater<number>;
};
type MenuItemYear = MenuItem<never, number>;
const years: MenuItemYear[] = [
  { value: 2024, text: "2024 (parcial)" },
  { value: 2023, text: "2023" },
  { value: 2022, text: "2022" },
  { value: 2021, text: "2021" },
  { value: 2020, text: "2020" },
  { value: 2019, text: "2019" },
  { value: 2018, text: "2018" },
  { value: 2017, text: "2017" },
  { value: 2016, text: "2016" },
  { value: 2015, text: "2015" }
];

export default function HeaderLocalNavigator(props: Props): JSX.Element {
  const { localUpdater, cities, year, yearUpdater } = props;
  const [uf, setUf] = useState<Local | null>(brazil);
  const [city, setCity] = useState<Local | null>(null);
  const [filteredCities, setFilteredCities] = useState<Local[]>(cities);
  const brazilWithStates = [brazil, ...regions, ...states];
  const countCities = formatNumber(filteredCities.length, 0);

  useEffect(() => {
    if (uf === null || uf.abbr === "BR") {
      setFilteredCities(cities);
      return;
    }

    const isRegion = uf.abbr === "";
    if (isRegion) {
      const ufs = ufByRegion[uf.ibge];
      setFilteredCities(cities.filter((item) => ufs.includes(item.abbr)));
    } else {
      setFilteredCities(cities.filter((item) => item.abbr === uf.abbr));
    }
  }, [uf, cities, setFilteredCities]);

  useEffect(() => {
    if (uf === null) {
      return;
    }
    localUpdater(uf);
    setCity(null);
  }, [uf, localUpdater]);

  useEffect(() => {
    if (city === null) {
      return;
    }

    localUpdater(city);
  }, [city, localUpdater]);

  const clearCitySelection = useCallback(
    (e: Event) => {
      e.preventDefault();
      setCity(null);
      if (uf) {
        localUpdater(uf);
      } else {
        localUpdater(brazil);
      }
    },
    [uf, setCity, localUpdater]
  );

  const selectedYearItem = years.find((i) => i.value === year);
  const [yearItem, setYearItem] = useState<MenuItemYear>(selectedYearItem || years[0]);
  useEffect(() => {
    yearUpdater(yearItem.value);
  }, [yearItem, yearUpdater]);

  return (
    <nav className={"header-nav"}>
      <ul className="flex flex-center">
        <li>
          <h3>Selecione Brasil, Região ou UF:</h3>
          <DropdownLocals
            locals={brazilWithStates}
            local={uf}
            setLocal={setUf}
            displayFlag={true}
            label="Ir para Brasil, regiões ou um dos 27 estados"
          />
        </li>
        <li>- ou -</li>
        <li>
          <h3>Selecione um município:</h3>
          <DropdownLocals
            locals={filteredCities}
            local={city}
            setLocal={setCity}
            label={`Ir para um dos ${countCities} municípios`}
          />
        </li>
        {city && uf && (
          <li>
            <a
              href="#"
              onClick={clearCitySelection}
              title={`Volta para visualização dos dados ${uf.preposition} ${uf.name}`}
            >
              <small>Limpar seleção do município</small>
            </a>
          </li>
        )}
        <li>
          <h3>Ano:</h3>
          <Dropdown items={years} updater={setYearItem} selected={yearItem} search={false} />
        </li>
      </ul>
    </nav>
  );
}
