import { MenuItem, MenuItems } from "@_types/MenuItems";
import { useEffect, useState } from "preact/hooks";

export default function useFilteredItems<T, K>(search: string, items: MenuItems<T, K>, limit: number): MenuItems<T, K> {
  const [filteredItems, setFilteredItems] = useState<MenuItems<T, K>>([]);

  useEffect(() => {
    const filter = search
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    const newItems = items.filter((item: MenuItem<T, K>) => {
      const valueToCompare = item.searchText ?? item.text.toLowerCase();
      return valueToCompare.indexOf(filter) > -1;
    });
    setFilteredItems(newItems.slice(0, limit));
  }, [search, setFilteredItems, items, limit]);

  return filteredItems;
}
