import { h, JSX } from "preact";
import { NutritionClassData } from "@_types/NutritionClass";
import genderMenuItems from "@data/genderMenuItems";
import { Age, Gender, Skin } from "@_types/Enums";
import { MenuItemAge, MenuItemGender, MenuItemSkin } from "@_types/MenuItems";
import skinMenuItems from "@data/skinMenuItems";
import { indexByClassId } from "@lib/nutritionClassDataFilters";
import nutritionAgeMenuItems from "@data/nutritionAgeMenuItems";
import useChildLabelFunction from "@hooks/useChildLabelFunction";
import getNutritionClassColumns from "@lib/getNutritionClassColumns";
import SectionNutritionTableNotes from "@components/SectionNutritionTableNotes";
import SectionNutritionTableCellPopulation from "@components/SectionNutritionTableCellPopulation";
import SectionNutritionTableCellNutritionClass from "@components/SectionNutritionTableCellNutritionClass";

const genders = {} as Record<Gender, MenuItemGender>;
const skins = {} as Record<Skin, MenuItemSkin>;
const ages = {} as Record<Age, MenuItemAge>;
genderMenuItems.forEach((item) => (genders[item.value] = item));
skinMenuItems.forEach((item) => (skins[item.value] = item));
nutritionAgeMenuItems.forEach((item) => (ages[item.value] = item));

type Props = {
  nutritionClasses: NutritionClassData[];
  age: Age;
  gender: Gender;
  skin: Skin;
};

export default function SectionNutritionHistoryTable(props: Props): JSX.Element {
  const { nutritionClasses, age, gender, skin } = props;
  const nutritionClassesFiltered = nutritionClasses.filter(
    (item) => item.s === skin && item.g === gender && item.a === age
  );
  const nutritionClassesFilteredOrdered = nutritionClassesFiltered.sort((a, b) => b.y - a.y);
  const groups = [nutritionClassesFilteredOrdered];

  return (
    <div className={"container"}>
      <div className="nutrition-table-complete">
        <table>
          <thead>
          <tr>
            <th className="td-skin">Ano</th>
            <th className="td-pop">Crianças Acompanhadas</th>
            {getNutritionClassColumns(age).map((item) => (
              <th key={item} className={`td-${item.key}`}>
                {item.title}
              </th>
            ))}
          </tr>
          </thead>
          {groups.map((group) => {
            return <TableBody key={group} classes={group} />;
          })}
        </table>

        <SectionNutritionTableNotes />
      </div>
    </div>
  );
}

type TableBodyProps = {
  classes: NutritionClassData[];
};

function TableBody(props: TableBodyProps): JSX.Element | null {
  const { classes } = props;

  if (classes.length === 0) {
    return null;
  }

  return (
    <tbody>
      {classes.map((item: NutritionClassData) => {
        const gender = genders[item.g];
        const skin = skins[item.s];
        const age = ages[item.a];
        return <TableRow key={item} age={age} gender={gender} skin={skin} item={item} />;
      })}
    </tbody>
  );
}

type RowProps = {
  gender: MenuItemGender;
  age: MenuItemAge;
  skin: MenuItemSkin;
  item: NutritionClassData;
};

function TableRow(props: RowProps): JSX.Element {
  const { age, skin, gender, item } = props;
  const classes = indexByClassId(item.classes);
  const childLabelFunction = useChildLabelFunction(gender, age);
  const columns = getNutritionClassColumns(age.value);

  return (
    <tr key={item}>
      <td>
        <strong>{item.y}</strong>
      </td>

      <SectionNutritionTableCellPopulation
        year={item.y}
        skin={skin.value}
        totalChildren={item.children}
        estimatedPop={item.estPop}
        childLabelFunction={childLabelFunction}
      />
      {columns.map((column) => {
        return (
          <SectionNutritionTableCellNutritionClass
            key={column}
            className={`td-nutrition-class`}
            item={classes[column.classId]}
            childLabelFunction={childLabelFunction}
          />
        );
      })}
    </tr>
  );
}