import { Fragment, h, JSX } from "preact";
import { MenuItemAge } from "@_types/MenuItems";
import { NutritionClass, NutritionClassData } from "@_types/NutritionClass";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import BoxHighlightNumber from "@components/BoxHighlightNumber";
import formatNutritionClass from "@lib/formatNutritionClass";
import getNutritionClassColumns from "@lib/getNutritionClassColumns";
import BoxHistory, { HistoryData } from "@components/BoxHistory";
import { useCallback, useEffect, useState } from "preact/hooks";
import ButtonHistory from "@components/ButtonHistory";
import partialYear from "@data/partialYear";
import {AppSection} from "@_types/Enums";

type Props = {
  age: MenuItemAge;
  nutritionClassData: NutritionClassData;
  historyData: NutritionClassData[];
  theme: string;
  skinLabel: string;
  childLabelFunc: ChildLabelFunction;
  svgIcons: JSX.Element[];
  localLabel: string;
  year: number;
  historyDescription: string;
};

let permanentHistoryIdSelected: string | null = null;

export default function SectionNutritionClassesComplete(props: Props): JSX.Element {
  const {
    nutritionClassData,
    historyData,
    historyDescription,
    skinLabel,
    childLabelFunc,
    age,
    localLabel,
    theme,
    year
  } = props;
  const classes = nutritionClassData.classes;
  const columns = getNutritionClassColumns(age.value);

  const [historyIdSelected, setHistoryIdSelected] = useState<string | null>(permanentHistoryIdSelected);
  const onClickHistoryButton = useCallback(
    (id: string) => setHistoryIdSelected(id !== historyIdSelected ? id : null),
    [historyIdSelected, setHistoryIdSelected]
  );
  const onCloseClick = useCallback(() => setHistoryIdSelected(null), [setHistoryIdSelected]);
  useEffect(() => {
    permanentHistoryIdSelected = historyIdSelected;
  }, [historyIdSelected]);

  let historyContent: JSX.Element = <Fragment />;
  if (historyIdSelected) {
    const column = columns[parseInt(historyIdSelected, 10)];
    const historyDataFormatted: HistoryData = historyData.map((item: NutritionClassData) => {
      const i = item.classes.find((_item: NutritionClass) => _item.id === column.classId);
      if (i === undefined) {
        return {
          year: item.y,
          isPartial: item.y === partialYear,
          isCurrent: item.y === year,
          number: 0,
          percent: 0
        };
      }

      return {
        year: item.y,
        number: i.c,
        percent: i.p,
        total: item.children,
        isPartial: item.y === partialYear,
        isCurrent: item.y === year
      };
    });
    historyContent = (
      <BoxHistory
        title={`Histórico - ${column.title}`}
        description={historyDescription}
        historyData={historyDataFormatted}
        childLabelFunction={childLabelFunc}
        onCloseClick={onCloseClick}
        year={year}
        appSectionType={AppSection.NUTRITION_CLASS}
      />
    );
  }

  const classHistoryOpen = historyIdSelected ? "mode-history-open" : "";

  return (
    <Fragment>
      <div className={`nutrition-classes mode-complete ${classHistoryOpen} flex flex-h-space-between margin-vertical`}>
        {columns.map((column, index) => {
          const id = `${index}`;
          const item = formatNutritionClass(classes.find((_item) => _item.id === column.classId));
          const childLabel = childLabelFunc(item.children);

          let numberLabel, mainValue, numberType: "absolute" | "percent";
          if (nutritionClassData.children < 100) {
            numberLabel = childLabel;
            mainValue = item.children;
            numberType = "absolute";
          } else {
            numberLabel = `${item.percentPerfectRounded} em cada 100 ${childLabelFunc(0)}`;
            mainValue = item.percent;
            numberType = "percent";
          }

          if (historyIdSelected && historyIdSelected !== `${index}`) {
            return null;
          }

          return (
            <article key={item} className={`theme-${column.key} ${theme}`}>
              <h1>{column.title}</h1>
              <BoxHighlightNumber value={mainValue} type={numberType}>
                {numberLabel} {skinLabel} {age.text}.
              </BoxHighlightNumber>
              {item.children > 0 && (
                <div>
                  <p>
                    De acordo com o SISVAN, em {year} foram registrados <strong>{item.childrenFormatted}</strong>{" "}
                    {childLabel} com {column.title.toLowerCase()} - {localLabel}.
                  </p>
                </div>
              )}
              <ButtonHistory id={id} isOpen={historyIdSelected === id} theme={"light"} onClick={onClickHistoryButton} />
            </article>
          );
        })}

        {historyContent}
      </div>
    </Fragment>
  );
}
