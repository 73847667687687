import { h, JSX } from "preact";
import { Age, Gender, Mode, Skin } from "@_types/Enums";
import { MenuItemAge, MenuItemGender, MenuItemMode, MenuItemSkin } from "@_types/MenuItems";
import { NutritionClassData } from "@_types/NutritionClass";
import { Local } from "@_types/Local";
import formatLocalName from "@lib/formatLocalName";
import SvgIcon from "@components/SvgIcon";
import useChildLabelFunction from "@hooks/useChildLabelFunction";
import SectionNutritionClassesSimplified from "@components/SectionNutritionClassesSimplified";
import SectionNutritionClassesComplete from "@components/SectionNutritionClassesComplete";
import useSkinLabel from "@hooks/useSkinLabel";

type Props = {
  openModal: () => void;
  mode: MenuItemMode;
  age: MenuItemAge;
  gender: MenuItemGender;
  skin: MenuItemSkin;
  nutritionClassData: NutritionClassData;
  historyData: NutritionClassData[];
  historyDescription: string;
  local: Local;
  year: number;
};

export default function SectionNutritionClasses(props: Props): JSX.Element {
  const { openModal, historyData, historyDescription, nutritionClassData, local, mode, age, gender, skin, year } =
    props;
  const svgIcons = prepareSvgIcons(gender);
  const theme = `theme-${skin.extraClass}`;
  const childLabelFunc = useChildLabelFunction(gender, age);
  const skinLabel = useSkinLabel(skin);
  const localLabel = formatLocalName(local);

  let content = null;
  if (mode.value === Mode.simplified) {
    content = (
      <SectionNutritionClassesSimplified
        openModal={openModal}
        age={age}
        nutritionClassData={nutritionClassData}
        historyData={historyData}
        historyDescription={historyDescription}
        skinLabel={skinLabel}
        childLabelFunc={childLabelFunc}
        svgIcons={svgIcons}
        localLabel={localLabel}
        year={year}
      />
    );
  }

  if (mode.value === Mode.complete) {
    content = (
      <SectionNutritionClassesComplete
        age={age}
        nutritionClassData={nutritionClassData}
        historyData={historyData}
        historyDescription={historyDescription}
        theme={theme}
        skinLabel={skinLabel}
        childLabelFunc={childLabelFunc}
        svgIcons={svgIcons}
        localLabel={localLabel}
        year={year}
      />
    );
  }

  return (
    <div class="container">
      {content}
    </div>
  );
}

function prepareSvgIcons(gender: MenuItemGender): JSX.Element[] {
  const svgIcons: JSX.Element[] = [];
  if (gender.value === Gender.all) {
    svgIcons.push(<SvgIcon icon="boy-happy" />);
    svgIcons.push(<SvgIcon icon="girl-happy" />);
  } else if (gender.value === Gender.m) {
    svgIcons.push(<SvgIcon icon="boy-happy" />);
  } else if (gender.value === Gender.f) {
    svgIcons.push(<SvgIcon icon="girl-happy" />);
  }
  return svgIcons;
}
