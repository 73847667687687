import { useCallback } from "preact/hooks";

export default function usePreventDefault(callback: () => void): (e: Event) => void {
  return useCallback(
    (e: Event) => {
      e.preventDefault();
      callback();
    },
    [callback]
  );
}
