import { ComponentChildren, h, JSX } from "preact";
import { useCallback, useState } from "preact/hooks";
import { PopoverState } from "./Types";
import PopoverContext from "./PopoverContext";
import usePopper from "./usePopper";
import useDocumentListenerToClosePopoverAfterClick from "./useDocumentListenerToClosePopoverAfterClick";
import style from "./style.scss";
import Markdown from "@components/Markdown";

type Props = {
  children: ComponentChildren;
};

export default function PopoverProvider(props: Props): JSX.Element {
  const [popoverState, setPopoverState] = useState<PopoverState>(null);
  const refPopover = usePopper(popoverState, 16);
  useDocumentListenerToClosePopoverAfterClick(popoverState, setPopoverState, refPopover);

  const onClose = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setPopoverState(null);
    },
    [setPopoverState]
  );

  return (
    <PopoverContext.Provider value={setPopoverState}>
      {props.children}

      <div
        className={`${style.overlay} ${style.popover}`}
        role="Popover"
        ref={refPopover}
        data-show={popoverState ? 1 : 0}
      >
        <a href="#" className={style.close} onClick={onClose}>
          fechar
        </a>
        <h1>{popoverState?.title}</h1>

        <Markdown content={popoverState?.text ?? ""} />

        <div className={style.arrow} data-popper-arrow="" />
      </div>
    </PopoverContext.Provider>
  );
}
