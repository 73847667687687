import { MouseEventCallback } from "./Types";
import { useCallback, useContext } from "preact/hooks";
import PopoverContext from "./PopoverContext";

export default function usePopover(title: string, text: string): MouseEventCallback {
  const setPopover = useContext(PopoverContext);
  const onClick = useCallback(
    (event: MouseEvent) => {
      let target = event.target;
      if (target instanceof SVGElement) {
        target = findParentOfSvg(target);
      }

      if (setPopover === null || !(target instanceof HTMLElement)) {
        return;
      }

      setPopover({
        element: target,
        text: `${text}`,
        title
      });
    },
    [setPopover, title, text]
  );

  return onClick;
}

function findParentOfSvg(element: Element | null): Element | null {
  if (element instanceof HTMLElement || element === null) {
    return element;
  }

  return findParentOfSvg(element.parentElement);
}
