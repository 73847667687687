import { h, JSX } from "preact";
import { StateUpdater } from "preact/hooks";
import { MenuItem, MenuItems } from "@_types/MenuItems";
import Dropdown from "@components/Dropdown";

type Props<T, K> = {
  title: string;
  items: MenuItems<T, K>;
  selected: MenuItem<T, K>;
  updater: StateUpdater<MenuItem<T, K>>;
  extraClass?: string;
};

export default function NavFilter<T, K>(props: Props<T, K>): JSX.Element {
  return (
    <nav className={props.extraClass ?? ""}>
      <h3>{props.title}</h3>
      <div>
        <Dropdown items={props.items} selected={props.selected} search={false} updater={props.updater} />
      </div>
    </nav>
  );
}
