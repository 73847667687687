import { Fragment, h, JSX } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { Local } from "@_types/Local";
import { MenuItemAge, MenuItemGender, MenuItemMode, MenuItemSkin } from "@_types/MenuItems";
import HeaderTitle from "@components/HeaderTitle";
import BoxCoverage from "@components/BoxCoverage";
import NutritionClasses from "@components/SectionNutritionClasses";
import NutritionTable from "@components/SectionNutritionTable";
import NavFilter from "@components/NavFilter";
import textNutrition from "@texts/textNutrition";
import textCoverageExtraInfo from "@texts/textCoverageExtraInfo";
import nutritionAgeMenuItems from "@data/nutritionAgeMenuItems";
import genderMenuItems from "@data/genderMenuItems";
import modeMenuItems from "@data/modeMenuItems";
import {AppSection, Mode, Skin} from "@_types/Enums";
import capitalizeFirstLetter from "@lib/capitalizeFirstLetter";
import { NutritionClassData } from "@_types/NutritionClass";
import { filterByAge } from "@lib/nutritionClassDataFilters";
import useChildLabelFunction from "@hooks/useChildLabelFunction";
import formatLocalName from "@lib/formatLocalName";
import { ChildLabelFunction } from "@_types/ChildLabelFunction";
import textNutritionNoData from "@texts/textNutritionNoData";
import SectionNutritionHistoryTable from "@components/SectionNutritionHistoryTable";
import SectionNutritionHistorySimplifiedTable from "@components/SectionNutritionHistorySimplifiedTable";
import { HistoryData } from "@components/BoxHistory";
import useHistoryDescription from "@hooks/useHistoryDescription";
import skinMenuItems from "@data/skinMenuItems";
import BoxAlertContainer from "@components/BoxAlertContainer";
import partialYear from "@data/partialYear";
import textNoteAboutYellowSkinProblem from "@texts/textNoteAboutYellowSkinProblem";

type Props = {
  openModal: () => void;
  local: Local;
  nutritionClasses: NutritionClassData[];
  year: number;
};

let selectedAge: MenuItemAge = nutritionAgeMenuItems[4];
let selectedGender: MenuItemGender = genderMenuItems[0];
let selectedSkin: MenuItemSkin = skinMenuItems[0];
let selectedMode: MenuItemMode = modeMenuItems[0];

export default function SectionNutrition(props: Props): JSX.Element {
  const { local, nutritionClasses, year, openModal } = props;

  const [age, setAge] = useState<MenuItemAge>(selectedAge);
  const [gender, setGender] = useState<MenuItemGender>(selectedGender);
  const [skin, setSkin] = useState<MenuItemSkin>(selectedSkin);
  const [mode, setMode] = useState<MenuItemMode>(selectedMode);

  useEffect(() => {
    selectedAge = age;
  }, [age]);
  useEffect(() => {
    selectedGender = gender;
  }, [gender]);
  useEffect(() => {
    selectedSkin = skin;
  }, [skin]);
  useEffect(() => {
    selectedMode = mode;
  }, [mode]);

  const localLabel = formatLocalName(local);
  const childLabelFunction = useChildLabelFunction(gender, age);
  const historyDescription = useHistoryDescription(childLabelFunction, age, gender, skin);

  const historyData: NutritionClassData[] = useMemo(() => {
    return nutritionClasses.filter((item: NutritionClassData) => {
      return item.a === age.value && item.s === skin.value && item.g == gender.value;
    });
  }, [nutritionClasses, age, gender, skin]);

  const nutritionClassData = useMemo(() => {
    return nutritionClasses.find((item: NutritionClassData) => {
      return item.y === year && item.a === age.value && item.s === skin.value && item.g == gender.value;
    });
  }, [nutritionClasses, year, age, gender, skin]);

  const numOfChildren = nutritionClassData?.children || 0;
  const estimatedPop = nutritionClassData?.estPop;
  const childLabel = childLabelFunction(numOfChildren);

  let filtersText = `${childLabel} ${age.text}`;
  if (skin.value !== Skin.all) {
    filtersText += ` - Raça/cor ${capitalizeFirstLetter(skin.text)}`;
  }

  let historyDataFormatted: HistoryData = [];
  let displayNutritionCoverage = true;
  let content;
  if (nutritionClassData === undefined) {
    displayNutritionCoverage = false;
    const title = `Nenhum acompanhamento de IMC por idade para ${filtersText}`;
    content = <BoxAlertContainer title={title} description={textNutritionNoData} />;
  } else if (mode.value === Mode.analytical) {
    displayNutritionCoverage = false;
    content = (
      <NutritionTable
        nutritionClasses={filterByAge(nutritionClasses, age.value)}
        age={age.value}
        gender={gender.value}
        skin={skin.value}
        year={year}
      />
    );
  } else if (mode.value === Mode.history) {
    displayNutritionCoverage = false;
    content = (
      <SectionNutritionHistoryTable
        nutritionClasses={filterByAge(nutritionClasses, age.value)}
        age={age.value}
        gender={gender.value}
        skin={skin.value}
      />
    );
  } else if (mode.value === Mode.historySimplified) {
    displayNutritionCoverage = false;
    content = (
      <SectionNutritionHistorySimplifiedTable
        nutritionClasses={filterByAge(nutritionClasses, age.value)}
        age={age.value}
        gender={gender.value}
        skin={skin.value}
      />
    );
  } else if (mode.value === Mode.simplified || mode.value === Mode.complete) {
    historyDataFormatted = historyData.map((item: NutritionClassData) => {
      return {
        year: item.y,
        number: item.children,
        percent: item.estPop ? (item.children / item.estPop) * 100 : undefined,
        total: item.estPop || undefined,
        isCurrent: item.y === year,
        isPartial: item.y === partialYear
      };
    });

    content = (
      <NutritionClasses
        local={local}
        mode={mode}
        age={age}
        gender={gender}
        skin={skin}
        nutritionClassData={nutritionClassData}
        historyData={historyData}
        historyDescription={historyDescription}
        year={year}
        openModal={openModal}
      />
    );
  }

  return (
    <section>
      <HeaderTitle title="Estado Nutricional" local={local} description={textNutrition} year={year} />

      <div class="container">
        <nav className="nav-filters | flex flex-h-space-between | margin-vertical">
          <NavFilter title="Faixa Etária" items={nutritionAgeMenuItems} selected={age} updater={setAge} />
          <NavFilter title="Filtro por Sexo" items={genderMenuItems} selected={gender} updater={setGender} />
          <NavFilter
            title="Filtro por Raça/cor"
            items={skinMenuItems}
            selected={skin}
            updater={setSkin}
            extraClass="nav-skin"
          />
          <NavFilter title="Modo de Visualização" items={modeMenuItems} selected={mode} updater={setMode} />
        </nav>
      </div>

      {content}

      {displayNutritionCoverage && (
        <Fragment>
          <NutritionClassCoverage
            filtersText={filtersText}
            numOfChildren={numOfChildren}
            localLabel={localLabel}
            estimatedPop={estimatedPop}
            childLabelFunc={childLabelFunction}
            historyData={historyDataFormatted}
            year={year}
            hasAsterisk={skin.value === Skin.yellow}
          />
        </Fragment>
      )}

      {
        displayNutritionCoverage && skin.value === Skin.yellow &&
        <div class="container">
          <p>
            <br />
            * <em>{textNoteAboutYellowSkinProblem}</em>
          </p>
        </div>
      }

    </section>
  );
}

type NutritionClassCoverageProps = {
  filtersText: string;
  numOfChildren: number;
  localLabel: string;
  estimatedPop: number | undefined;
  childLabelFunc: ChildLabelFunction;
  historyData?: HistoryData;
  historyDescription?: string;
  year: number;
  hasAsterisk: boolean;
};

function NutritionClassCoverage(props: NutritionClassCoverageProps) {
  const {
    filtersText,
    numOfChildren,
    estimatedPop,
    childLabelFunc,
    historyData = [],
    historyDescription = "",
    year,
    hasAsterisk
  } = props;
  const percentOfChildren = estimatedPop ? (numOfChildren / estimatedPop) * 100 : null;

  return (
    <BoxCoverage
      title={`Taxa de Cobertura IMC por Idade em ${year} - ${capitalizeFirstLetter(filtersText)}`}
      numOfChildren={numOfChildren}
      percentOfChildren={percentOfChildren}
      populationEstimated={estimatedPop}
      childLabelFunc={childLabelFunc}
      description={`${textCoverageExtraInfo}`}
      historyData={historyData}
      historyDescription={historyDescription}
      year={year}
      hasAsterisk={hasAsterisk}
      appSectionType={AppSection.NUTRITION_CLASS}
    />
  );
}
