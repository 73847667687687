import { ComponentChildren, h, JSX } from "preact";
import formatNumber from "@lib/formatNumber";

type Props = {
  value: number | null;
  type?: "absolute" | "percent" | "percentRounded";
  children: ComponentChildren;
  hasAsterisk?: boolean
};

export default function BoxHighlightNumber(props: Props): JSX.Element | null {
  const value = props.value;
  const type = props?.type ?? "absolute";
  const hasAsterisk = props?.hasAsterisk ?? false

  return (
    <div className="box-highlight-number">
      <Number value={value} type={type} hasAsterisk={hasAsterisk} />
      <p>{props.children}</p>
    </div>
  );
}

type NumberProps = {
  value: number | null;
  type?: "absolute" | "percent" | "percentRounded";
  hasAsterisk?: boolean
};

function Number(props: NumberProps): JSX.Element | null {
  const { value, type } = props;
  const hasAsterisk = props?.hasAsterisk ?? false

  if (value === null) {
    return null;
  }

  const decimal = type === "percent" ? 2 : 0;
  const valueFormatted = type === "absolute" ? formatNumber(value, decimal) : `${formatNumber(value, decimal)}%`;
  return <strong>{valueFormatted}{ hasAsterisk ? '*' : ''}</strong>;
}
