import { NutritionClass, NutritionClassData } from "@_types/NutritionClass";
import { NutritionClassID } from "@_types/Enums";
import { filterOverweight, filterUnderweight } from "@lib/nutritionClassDataFilters";

export function reduceToNormalweight(data: NutritionClassData): NutritionClass {
  const result = data.classes.find((item) => item.id === NutritionClassID.normal);
  if (result === undefined) {
    return {
      id: NutritionClassID.normal,
      p: 0,
      ppr: 0,
      c: 0
    };
  }

  return result;
}

export function reduceToOverweight(data: NutritionClassData): NutritionClass {
  return reduceToAggregate(filterOverweight(data.classes), NutritionClassID.overweightAggregated);
}

export function reduceToUnderweight(data: NutritionClassData): NutritionClass {
  return reduceToAggregate(filterUnderweight(data.classes), NutritionClassID.underweight0);
}

function reduceToAggregate(classes: NutritionClass[], id: NutritionClassID): NutritionClass {
  return classes.reduce(
    (previous, current) => {
      return {
        id: previous.id,
        p: previous.p + current.p,
        ppr: previous.ppr + current.ppr,
        c: previous.c + current.c
      };
    },
    {
      id,
      p: 0,
      ppr: 0,
      c: 0
    }
  );
}
