import { h, JSX } from "preact";
import Markdown from "@components/Markdown";

type Props = {
  title: string;
  description: string;
};

export default function BoxAlert(props: Props): JSX.Element {
  return (
    <div className="box-alert margin-vertical">
      <h1>{props.title}</h1>
      <p>
        <Markdown content={props.description} />
      </p>
    </div>
  );
}
