const textCoverageExtraInfo = `O SISVAN disponibiliza o número absoluto de crianças acompanhadas em cada município mas não oferece o percentual destas crianças em relação ao total de crianças do município. 

&nbsp;

Para os anos de 2015 até 2021 calculamos a **estimativa percentual de crianças acompanhadas** em cada faixa etária através da estimativa de população residente do DATASUS.
A partir de 2022 os dados de população são oficiais do Censo Demográfico 2022 e a partir deles também foi possível exibir os dados de cobertura por raça/cor.

&nbsp;

Não é possível considerar o percentual como uma amostra precisa da população em função das características de coleta de informações locais.`;

export default textCoverageExtraInfo;
