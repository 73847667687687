import { h, JSX } from "preact";
import formatNumber from "@lib/formatNumber";
import {NutritionClass} from "@_types/NutritionClass";

type CellProps = {
  className: string;
  item: NutritionClass | undefined;
  childLabelFunction: (value: number) => string;
};

export default function SectionNutritionTableCellNutritionClass(props: CellProps): JSX.Element {
  const { item, childLabelFunction, className } = props;
  if (!item) {
    return (
      <td className={className}>
        <strong>--</strong>
        <br />
        <small>0 {childLabelFunction(0)}</small>
      </td>
    );
  }
  const percent = formatNumber(item.p, 1);
  const children = formatNumber(item.c, 0);

  return (
    <td className={className}>
      <strong>{percent}%</strong>
      <br />
      <small>
        {children} {childLabelFunction(item.c)}
      </small>
    </td>
  );
}