import { MenuItemAge } from "@_types/MenuItems";
import { Age } from "@_types/Enums";

const nutritionAgeMenuItems: MenuItemAge[] = [
  { value: Age["0m5a"], text: "de 0 até 4 anos" },
  { value: Age["5a10a"], text: "de 5 até 9 anos" },
  { value: Age["0a10a"], text: "de 0 até 9 anos" },
  { value: Age["10a19a"], text: "de 10 até 19 anos" },
  { value: Age["0a19a"], text: "de 0 a 19 anos" }
];

export default nutritionAgeMenuItems;
