import { h, JSX } from "preact";
import { useRef } from "preact/hooks";
import useToggleState from "@hooks/useToggleState";
import { MenuItem, MenuItems, MenuItemUpdater } from "@_types/MenuItems";
import Item from "./Item";
import Button from "./Button";
import useFocusOnOpen from "./useFocusOnOpen";
import useSearch from "./useSearch";
import useFilteredItems from "./useFilteredItems";
import useDocumentListenerToCloseLayersAfterClick from "@hooks/useDocumentListenerToCloseLayersAfterClick";

const defaultLimit = 100;

type Props<T, K> = {
  items: MenuItems<T, K>;
  updater: MenuItemUpdater<T, K>;
  selected?: MenuItem<T, K> | null;
  label?: string;
  search?: boolean;
  limit?: number;
  theme?: DropdownThemes;
};

export type DropdownThemes = "mobilize";

export default function Dropdown<T, K>(props: Props<T, K>): JSX.Element {
  const displaySearch = props.search ?? true;
  const itemsLimit = props.limit ?? defaultLimit;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, toggleOpen] = useToggleState();
  const [search, onInputSearch] = useSearch();
  const filteredItems = useFilteredItems<T, K>(search, props.items, itemsLimit);
  useFocusOnOpen(isOpen, inputRef);
  useDocumentListenerToCloseLayersAfterClick(isOpen, toggleOpen, dropdownRef);

  const cssClasses = ["dropdown"];
  if(isOpen) {
    cssClasses.push("open");
  }

  if(props.theme) {
    cssClasses.push(`dropdown-theme-${props.theme}`);
  }

  return (
    <div className={cssClasses.join(" ")} ref={dropdownRef}>
      <Button label={props.label} itemSelected={props.selected} toggleOpen={toggleOpen} />
      <div className="dropdown-content">
        {displaySearch && (
          <input ref={inputRef} type="text" placeholder="Busca.." value={search} onInput={onInputSearch} />
        )}
        <div>
          {filteredItems.map((item: MenuItem<T, K>) => {
            return <Item key={item} item={item} updater={props.updater} closer={toggleOpen} />;
          })}
        </div>
      </div>
    </div>
  );
}
