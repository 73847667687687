// Original reference: https://gist.github.com/scwood/e58380174bd5a94174c9f08ac921994f
// Just added types and split the big function into many smaller functions easier to understand
type MappedNumber = {
  floor: number;
  remainder: number;
  index: number;
};

export default function largestRemainderRound(numbers: number[], desiredTotal = 100): number[] {
  const result = numbers.map(mapNumber).sort(sortByRemainder);

  const lowerSum = result.reduce(sumFloor, 0);

  const delta = desiredTotal - lowerSum;
  for (let i = 0; i < delta; i++) {
    result[i].floor++;
  }

  return result.sort(sortByIndex).map(mapFloor);
}

function mapNumber(number: number, index: number): MappedNumber {
  return {
    floor: Math.floor(number),
    remainder: getRemainder(number),
    index
  };
}

function getRemainder(number: number): number {
  const remainder = number - Math.floor(number);
  return Math.round(remainder * 1000) / 1000;
}

function sortByRemainder(a: MappedNumber, b: MappedNumber): number {
  return b.remainder - a.remainder;
}

function sumFloor(sum: number, current: MappedNumber): number {
  return sum + current.floor;
}

function sortByIndex(a: MappedNumber, b: MappedNumber): number {
  return a.index - b.index;
}

function mapFloor(result: MappedNumber): number {
  return result.floor;
}
