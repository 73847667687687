import { useCallback, useState } from "preact/hooks";

export default function useToggleState(): [boolean, () => void] {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, [setOpen]);

  return [isOpen, toggleOpen];
}
