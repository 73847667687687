import { h, JSX } from "preact";

export default function Divider(): JSX.Element {


  return (
    // <hr class={"container"} />
    <div class="divider div-transparent" />
  );
}
