import { useEffect, useState } from "preact/hooks";
import { Local, LocalData } from "@_types/Local";
import { aggregateExtraNutritionClasses } from "@lib/aggregateExtraNutritionClasses";

const initialResultState = { nutritionClasses: [] };

export default function useLocalDataApi(localSelected: Local): [boolean, LocalData, Error | null] {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<LocalData>(initialResultState);

  useEffect(() => {
    const controller = new AbortController();

    let ibge = `${localSelected.ibge}`;
    if (ibge.length > 6) {
      ibge = ibge.substring(0, 6);
    }

    setLoading(true);
    setError(null);
    // setResult(initialResultState);

    fetch(`/api/${ibge}.json`, {
      method: "GET",
      signal: controller.signal,
      headers: {
        Accept: "application/json"
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then(
        (response: LocalData) => {
          response.nutritionClasses = aggregateExtraNutritionClasses(response.nutritionClasses);
          setResult(response);
          setLoading(false);
        },
        (error: Error) => {
          setError(error);
          setLoading(false);
        }
      );

    return () => {
      controller.abort();
    };
  }, [localSelected]);

  return [isLoading, result, error];
}
