import { h, JSX } from "preact";
import { Local } from "@_types/Local";
import SvgHelpIcon from "@components/SvgHelpIcon";
import formatLocalName from "@lib/formatLocalName";

type Props = {
  title: string;
  local: Local;
  year: number;
  description: string;
};

export default function HeaderTitle(props: Props): JSX.Element {
  const { title, local, description, year } = props;
  const localName = formatLocalName(local);
  return (
    <header className="header-title container">
      <div>
        <h1>
          {props.title} - <strong>{localName}</strong> - <strong>{year}</strong>
          <SvgHelpIcon title={title} description={description} />
        </h1>
      </div>
    </header>
  );
}
