import { h, JSX } from "preact";
import SvgIcon from "@components/SvgIcon";
import { usePopover } from "@components/Overlay";

type Props = {
  title: string;
  description: string;
};

export default function SvgHelpIcon(props: Props): JSX.Element {
  const onClick = usePopover(props.title, props.description);
  return (
    <span class="svg-help-icon" title="Clique para ver mais" onClick={onClick}>
      <SvgIcon icon="help" />
    </span>
  );
}
