import { h, JSX } from "preact";
import SvgIcon from "@components/SvgIcon";
import { SvgIconAvailable } from "@_types/Svg";

type Props = {
  label?: string;
  icon: SvgIconAvailable;
  onClick: () => void;
  theme: "primary" | "light" | "mobilize-primary" | "mobilize-light" | "orange";
  disabled?: boolean;
  extraCssClass?: string;
};

export default function ButtonIcon(props: Props): JSX.Element {
  const { label, icon, onClick, theme } = props;
  const disabled = props.disabled ?? false;
  const _onClick = (e: Event) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    onClick();
  };

  const cssClasses: string[] = ["button-icon", `button-icon-${theme}`, `button-icon-${icon}`];
  if (disabled) {
    cssClasses.push(`button-icon-disabled`);
  }
  if(props.extraCssClass) {
    cssClasses.push(props.extraCssClass);
  }

  return (
    <a href="#" className={cssClasses.join(" ")} onClick={_onClick} title={label}>
      <SvgIcon icon={icon} />
      {label && <span>{label}</span>}
    </a>
  );
}

type PropsLink = {
  label: string;
  icon: SvgIconAvailable;
  theme: "primary" | "light" | "mobilize-primary" | "mobilize-light" | "orange";
  address: string;
};

export function ButtonIconLink(props: PropsLink): JSX.Element {
  const { label, icon, address, theme } = props;

  return (
    <a
      className={`button-icon button-icon-${theme} button-icon-${icon}`}
      href={address}
      target={"_blank"}
      title={label}
      rel="noreferrer"
    >
      <SvgIcon icon={icon} />
      <span>{label}</span>
    </a>
  );
}

type PropsSocial = {
  icon: SvgIconAvailable;
  title: string;
  onClick: (e: Event) => void;
  fallbackUrl: string;
};

export function ButtonIconSocial(props: PropsSocial): JSX.Element {
  const { icon, onClick, title, fallbackUrl } = props;
  const theme = "mobilize-light";
  const cssClasses: string[] = ["button-icon", `button-icon-${theme}`, `button-icon-${icon}`];

  return (
    <a
      href={fallbackUrl}
      className={cssClasses.join(" ")}
      onClick={onClick}
      title={title}
      target={"_blank"}
      rel="noreferrer"
    >
      <SvgIcon icon={icon} />
    </a>
  );
}
