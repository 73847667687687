import { PopperState } from "./Types";
import { useLayoutEffect, useRef } from "preact/hooks";
import { createPopper } from "@popperjs/core";
import { RefObject } from "preact";

export default function usePopper(
  state: PopperState | null,
  arrowSize: number,
  placement: "top" | "bottom" = "bottom"
): RefObject<HTMLDivElement> {
  const refPopper = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!(state && refPopper.current)) {
      return;
    }

    const popperInstance = createPopper(state.element, refPopper.current, {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, arrowSize]
          }
        }
      ]
    });

    return () => {
      //ao criar uma nova garante a destruição da anterior
      popperInstance.destroy();
    };
  }, [state, refPopper, arrowSize, placement]);

  return refPopper;
}
