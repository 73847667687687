import { h, JSX } from "preact";
import { NutritionClassData } from "@_types/NutritionClass";
import genderMenuItems from "@data/genderMenuItems";
import { Age, Gender, Skin } from "@_types/Enums";
import { MenuItemAge, MenuItemGender, MenuItemSkin } from "@_types/MenuItems";
import skinMenuItems from "@data/skinMenuItems";
import { indexByClassId } from "@lib/nutritionClassDataFilters";
import SvgIcons from "@components/SvgIcons";
import capitalizeFirstLetter from "@lib/capitalizeFirstLetter";
import nutritionAgeMenuItems from "@data/nutritionAgeMenuItems";
import useChildLabelFunction from "@hooks/useChildLabelFunction";
import getNutritionClassColumns from "@lib/getNutritionClassColumns";
import SectionNutritionTableNotes from "@components/SectionNutritionTableNotes";
import SectionNutritionTableCellPopulation from "@components/SectionNutritionTableCellPopulation";
import SectionNutritionTableCellNutritionClass from "@components/SectionNutritionTableCellNutritionClass";

const genders = {} as Record<Gender, MenuItemGender>;
const skins = {} as Record<Skin, MenuItemSkin>;
const ages = {} as Record<Age, MenuItemAge>;
genderMenuItems.forEach((item) => (genders[item.value] = item));
skinMenuItems.forEach((item) => (skins[item.value] = item));
nutritionAgeMenuItems.forEach((item) => (ages[item.value] = item));

type Props = {
  nutritionClasses: NutritionClassData[];
  age: Age;
  gender: Gender;
  skin: Skin;
  year: number;
};

export default function SectionNutritionTable(props: Props): JSX.Element {
  const { nutritionClasses, age, gender, skin, year } = props;
  const nutritionClassesByYear = nutritionClasses.filter((item) => item.y === year);
  const groups = defineGroupsOfClasses(nutritionClassesByYear, gender, skin);

  return (
    <div class={"container"}>
      <div className="nutrition-table-analytics">
        <table>
          <thead>
            <tr>
              <th className="td-gender">Sexo</th>
              <th className="td-skin">Raça/cor*</th>
              <th className="td-pop">Crianças Acompanhadas</th>
              {getNutritionClassColumns(age).map((item) => (
                <th key={item}>{item.title}</th>
              ))}
            </tr>
          </thead>
          {groups.map((group) => {
            return <TableBody key={group} classes={group} />;
          })}
        </table>

        <SectionNutritionTableNotes />
      </div>
    </div>
  );
}

function defineGroupsOfClasses(classes: NutritionClassData[], gender: Gender, skin: Skin): NutritionClassData[][] {
  const groups = [];
  const filterGender = (item: NutritionClassData) => {
    if (gender === Gender.all) {
      return true;
    }

    return item.g === Gender.all || item.g === gender;
  };

  const filterSkin = (item: NutritionClassData) => {
    if (skin === Skin.all) {
      return true;
    }

    return (item.s === Skin.all && item.g === Gender.all) || item.s === skin;
  };

  const items = classes.filter(filterGender).filter(filterSkin);

  // gender all and skin all
  groups.push(items.filter((item) => item.g === Gender.all && item.s === Skin.all));

  // gender any and skin all
  groups.push(items.filter((item) => item.g !== Gender.all && item.s === Skin.all));

  // gender all and skin any
  groups.push(items.filter((item) => item.g === Gender.all && item.s !== Skin.all));

  if (gender === Gender.m || gender === Gender.all) {
    groups.push(items.filter((item) => item.g === Gender.m && item.s !== Skin.all));
  }

  if (gender === Gender.f || gender === Gender.all) {
    groups.push(items.filter((item) => item.g === Gender.f && item.s !== Skin.all));
  }

  return groups;
}

type TableBodyProps = {
  classes: NutritionClassData[];
};

function TableBody(props: TableBodyProps): JSX.Element | null {
  const { classes } = props;

  if (classes.length === 0) {
    return null;
  }

  return (
    <tbody>
      {classes.map((item: NutritionClassData) => {
        const gender = genders[item.g];
        const skin = skins[item.s];
        const age = ages[item.a];
        return <TableRow key={item} age={age} gender={gender} skin={skin} item={item} />;
      })}
    </tbody>
  );
}

type RowProps = {
  gender: MenuItemGender;
  age: MenuItemAge;
  skin: MenuItemSkin;
  item: NutritionClassData;
};

function TableRow(props: RowProps): JSX.Element {
  const { age, skin, gender, item } = props;
  const classes = indexByClassId(item.classes);
  const childLabelFunction = useChildLabelFunction(gender, age);
  const columns = getNutritionClassColumns(age.value);

  return (
    <tr key={item}>
      <CellGender gender={gender} />
      <CellSkin skin={skin} />
      <SectionNutritionTableCellPopulation
        year={item.y}
        skin={skin.value}
        totalChildren={item.children}
        estimatedPop={item.estPop}
        childLabelFunction={childLabelFunction}
      />
      {columns.map((column) => {
        return (
          <SectionNutritionTableCellNutritionClass
            key={column}
            className={`td-nutrition-class`}
            item={classes[column.classId]}
            childLabelFunction={childLabelFunction}
          />
        );
      })}
    </tr>
  );
}

type CellGenderProps = {
  gender: MenuItemGender;
};

function CellGender(props: CellGenderProps): JSX.Element {
  const { gender } = props;

  let content;
  if (gender.value === Gender.all) {
    content = "Todos";
  } else {
    content = <SvgIcons icons={gender.svgIcons || []} />;
  }
  return <td className="td-gender">{content}</td>;
}

type CellSkinProps = {
  skin: MenuItemSkin;
};

function CellSkin(props: CellSkinProps): JSX.Element {
  const { skin } = props;
  const content = capitalizeFirstLetter(skin.text);

  return <td className={`td-skin`}>{content}</td>;
}
