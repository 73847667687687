import { useMemo } from "preact/hooks";
import { MenuItemSkin } from "@_types/MenuItems";
import { Skin } from "@_types/Enums";

export default function useSkinLabel(skin: MenuItemSkin): string {
  return useMemo(() => {
    if (skin.value !== Skin.all) {
      return `de raça/cor ${skin.text}`;
    }

    return "";
  }, [skin]);
}
