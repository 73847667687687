import { h, JSX } from "preact";
import { useCallback } from "preact/hooks";
import SvgIcon from "@components/SvgIcon";

type Theme = "light" | "main";

type Props = {
  id: string;
  isOpen: boolean;
  onClick: (id: string) => void;
  theme?: Theme;
};

export default function ButtonHistory(props: Props): JSX.Element {
  const { isOpen, onClick, id } = props;
  const theme = props.theme ?? "main";
  const _onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      onClick(id);
    },
    [onClick, id]
  );

  return (
    <a href={"#"} className={`button-history button-history-${theme}`} onClick={_onClick}>
      <SvgIcon icon={"line-chart"} />
      {isOpen ? "Fechar Gráfico" : "Ver Gráfico"}
    </a>
  );
}

export function ButtonHistorySmall(props: Props): JSX.Element {
  const { isOpen, onClick, id } = props;
  const theme = props.theme ?? "main";
  const title = isOpen ? "Fechar Gráfico" : "Ver Gráfico";
  const _onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      onClick(id);
    },
    [onClick, id]
  );

  return (
    <a
      href={"#"}
      className={`button-history button-history-${theme} button-history-small`}
      onClick={_onClick}
      title={title}
    >
      <SvgIcon icon={"line-chart"} />
    </a>
  );
}
